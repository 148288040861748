<template>
  <div class="mx-auto px-4 pb-12 sm:px-4 lg:px-8">
    <BaseCard title="Pengeluaran Barang ke Mitra Usaha">
      <div class="flex flex-col">
        <div class="flex items-center justify-end space-x-2">
          <base-select
            id="order_status"
            :expand="false"
            v-model="order_status"
            @change="filterByShipped"
            :options="[
              {
                label: 'Semua',
                value: 'Persiapan,Diproses Gudang Pengeluaran',
              },
              {
                label: 'Diproses Gudang Pengeluaran',
                value: 'Diproses Gudang Pengeluaran',
              },
              {
                label: 'Ada Susulan',
                value: 'Ada Susulan',
              },
            ]"
          />
          <base-search
            v-model="search"
            @input="debounceSearch"
            placeholder="Cari kode atau nama"
          />
        </div>
        <div class="flex my-2 sm:-mx-6 lg:-mx-8">
          <div
            class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"
          >
            <PengeluaranTable
              @sort="handleSort"
              @change="onPageChangeOrder"
              @click-row="redirectToDetail"
            />
          </div>
        </div>
      </div>
    </BaseCard>
  </div>
</template>

<script>
import { debounce } from 'debounce';
import { mapGetters, mapActions } from 'vuex';
import { StorageService } from '@/services/storage.service';
import dayjs from 'dayjs';
import BaseSearch from '@/components/base/Search.vue';
import BaseCard from '@/components/base/BaseCard.vue';
import PengeluaranTable from '@/components/pengeluaran/pengeluaran-table.vue';

export default {
  name: 'Home',
  components: {
    BaseSearch,
    BaseCard,
    PengeluaranTable,
  },

  data() {
    return {
      order_status: 'Persiapan,Diproses Gudang Pengeluaran',
      search: null,
      openDetail: false,
      is_packed: false,
      dataPenjualan: {},
      dataBrgMasuk: {},
      detailBrgMasuk: {},
      cancelConfirmation: false,
    };
  },

  computed: {
    ...mapGetters({
      getStockMovements: 'stock_movements/getStockMovements',
      getOrders: 'orders/getOrders',
      isLoading: 'orders/getLoading',
    }),
    orderParams() {
      const orderParams = {
        sort: 'id',
        'filter[origin_warehouse_id]':
          StorageService.getUser().current_warehouse,
        'page[limit]': 5,
        'filter[order_status]': this.order_status,
        'filter[is_paid]': true,
        'filter[destination_office_category_id]': 5,
        include: 'destination-office,origin-warehouse,origin-office,order',
        fields: {
          orders:
            'area_name,grand_total_price_after_ppn,is_valid_for_shipment,updatedAt',
          offices: 'code,name',
          'simple-orders':
            'origin_code,destination_code,destination-office,origin-office,order_id,order,order_status,warehouse_status',
        },
      };
      return { ...orderParams };
    },
  },
  methods: {
    dayjs,
    ...mapActions({
      fetchStockMovements: 'stock_movements/fetchStockMovements',
      updateStockMovement: 'stock_movements/updateStockMovement',
      fetchOrders: 'orders/fetchOrders',
      updateOrder: 'orders/updateOrder',
    }),
    debounceSearch: debounce(function () {
      this.fetchOrders(this.orderParams);
    }, 300),
    onPageChangeOrder(page) {
      this.fetchOrders({
        ...page,
        ...this.orderParams,
      });
    },
    filterByShipped() {
      this.fetchOrders(this.orderParams);
    },
    handleSort() {},
    async cancelOrder(data) {
      if (data.attributes.has_warehouse_receipt) {
        await this.dataPenjualan.relationships['stock-movements'].data.map(
          (mov) => {
            const payloadDelete = {
              data: {
                type: 'stock-movements',
                id: mov.id,
                attributes: {
                  deletedAt: new Date(),
                },
              },
            };
            this.updateStockMovement(payloadDelete);
          }
        );
        const payload = {
          data: {
            type: 'orders',
            id: data.id,
            attributes: {
              is_valid_for_packing: false,
              has_warehouse_receipt: false,
            },
          },
        };
        await this.updateOrder(payload).then((response) => {
          if (response) {
            this.openDetail = false;
            this.fetchOrders(this.orderParams);
          }
        });
      } else {
        const payload = {
          data: {
            type: 'orders',
            id: data.id,
            attributes: {
              is_valid_for_packing: false,
            },
          },
        };
        this.updateOrder(payload).then((response) => {
          if (response) {
            this.openDetail = false;
            this.fetchOrders(this.orderParams);
          }
        });
      }
    },
    getRelationships(id) {
      let data = this.getOrders.included.filter(function (el) {
        return el.id === id;
      });
      return data[0];
    },
    redirectToDetail(data) {
      this.$router.push(
        `pengeluaran/barang-ke-member/${data.attributes.order_id}`
      );
    },
    sendOrder(data) {
      const payload = {
        data: {
          type: 'stock-movements',
          id: data.relationships['stock-movements'].data[0].id,
          attributes: {
            is_shipped: true,
          },
        },
      };
      this.updateStockMovement(payload).then((response) => {
        if (response) {
          this.is_packed = true;
          this.fetchOrders(this.orderParams);
        }
        this.openDetail = false;
      });
    },
    openConfirmation() {
      this.cancelConfirmation = !this.cancelConfirmation;
    },
  },
  created() {
    this.fetchOrders(this.orderParams);
    const handleEscape = () => {
      this.openDetail = false;
    };
    this.setEscape(handleEscape);
  },
};
</script>
