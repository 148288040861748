<template>
  <div class="mx-4 py-4 md:mx-6 md:py-6">
    <div class="mb-5 rounded-lg bg-white p-4 sm:p-6">
      <div class="flex justify-between border-b border-gray-200 pb-5">
        <h3 class="text-lg font-bold leading-6 text-gray-900">
          Pencatatan Data Tambah Poin
        </h3>
        <span
          v-if="is_detail"
          class="inline-flex items-center rounded-full bg-yellow-100 px-3 py-1 text-sm font-medium text-yellow-700"
        >
          Draft
        </span>
      </div>
      <div class="py-2">
        <div class="mt-6 grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-6">
          <div class="sm:col-span-6">
            <div class="field-inset-default w-full bg-gray-100">
              <label
                for="tanggal"
                class="block text-xs font-bold text-gray-700"
              >
                Tanggal
              </label>
              <input
                v-model="dataPenjualan.faktur.tanggal"
                disabled
                type="date"
                name="tanggal"
                id="tanggal"
                class="block w-full border-0 bg-gray-100 p-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
              />
            </div>
          </div>

          <div class="sm:col-span-3">
            <div
              class="field-inset-default relative w-full"
              :class="{ 'bg-gray-100': is_detail }"
            >
              <label for="name" class="block text-xs font-bold text-gray-700">
                Nomor Tetap / Sementara
              </label>
              <button
                v-if="!is_detail"
                type="button"
                @click="openModalMember"
                class="flex absolute inset-y-0 top-4 right-4 cursor-pointer items-center"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5 text-gray-500 hover:text-gray-700"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M9 9a2 2 0 114 0 2 2 0 01-4 0z" />
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a4 4 0 00-3.446 6.032l-2.261 2.26a1 1 0 101.414 1.415l2.261-2.261A4 4 0 1011 5z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
              <input
                @change="onChangeMember"
                :value="memberCode()"
                ref="focusNamaStockist"
                @blur="searchMember"
                type="text"
                name="name"
                id="name"
                class="block w-full border-0 px-0 py-0 text-gray-900 placeholder-gray-200 focus:ring-0 disabled:bg-gray-100 sm:text-sm"
                placeholder="Masukkan Mitra Usaha"
                :disabled="is_detail"
              />
            </div>
          </div>
          <div class="sm:col-span-3">
            <div class="field-inset-default relative bg-gray-100">
              <label
                for="nama_mitra"
                class="block text-xs font-bold text-gray-700"
              >
                Nama Mitra Usaha
              </label>
              <input
                v-model="dataPenjualan.faktur.nama_pemilik"
                readonly
                type="text"
                name="nama_mitra"
                id="nama_mitra"
                class="block w-full border-0 bg-gray-100 p-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                disabled
              />
            </div>
          </div>

          <div class="sm:col-span-3">
            <div class="field-inset-default relative w-full bg-gray-100">
              <label for="name" class="block text-xs font-bold text-gray-700">
                Kode Gudang
              </label>
              <input
                v-model="dataPenjualan.faktur.kode_gudang"
                type="text"
                name="name"
                id="name"
                class="block w-full border-0 bg-gray-100 px-0 py-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                disabled
              />
            </div>
          </div>
          <div class="sm:col-span-3">
            <div class="field-inset-default relative bg-gray-100">
              <label
                for="wilayah_harga"
                class="block text-xs font-bold text-gray-700"
              >
                Harga Wilayah Stockist
              </label>
              <input
                disabled
                v-model="dataPenjualan.faktur.wilayah_harga"
                @change="changeArea"
                type="text"
                name="wilayah_harga"
                id="wilayah_harga"
                class="block w-full border-0 bg-gray-100 p-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                placeholder="Masukkan Wilayah Harga"
              />
            </div>
          </div>

          <div class="sm:col-span-3">
            <div class="field-inset-default relative w-full bg-gray-100">
              <label for="name" class="block text-xs font-bold text-gray-700">
                No Upline
              </label>
              <input
                v-model="dataPenjualan.faktur.no_upline"
                placeholder="No Upline"
                type="text"
                name="no_upline"
                id="no_upline"
                class="block w-full border-0 bg-gray-100 px-0 py-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                disabled
              />
            </div>
          </div>
          <div class="sm:col-span-3">
            <div class="field-inset-default relative bg-gray-100">
              <label
                for="wilayah_harga"
                class="block text-xs font-bold text-gray-700"
              >
                Nama Upline
              </label>
              <input
                v-model="dataPenjualan.faktur.name_upline"
                placeholder="Nama Upline"
                type="text"
                name="name_upline"
                id="name_upline"
                class="block w-full border-0 bg-gray-100 p-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                disabled
              />
            </div>
          </div>

          <div class="sm:col-span-6">
            <label for="name" class="text-xs font-bold text-gray-700">
              Metode Pengiriman
            </label>
            <div class="flex my-3 items-center space-x-8">
              <div
                v-if="
                  is_detail ? dataPenjualan.faktur.metode === 'pickup' : true
                "
                class="items-center text-sm text-gray-700"
              >
                <input
                  v-model="dataPenjualan.faktur.metode"
                  @change="changeMetode"
                  type="radio"
                  name="metode_pengiriman"
                  value="pickup"
                  class="mr-1 h-4 w-4 cursor-pointer border-gray-300 text-gray-600 focus:ring-gray-500"
                  aria-labelledby="privacy-setting-1-label"
                  aria-describedby="privacy-setting-1-description"
                  :disabled="is_detail"
                />
                Diambil
              </div>
              <div
                v-if="
                  is_detail ? dataPenjualan.faktur.metode === 'delivery' : true
                "
                class="items-center text-sm text-gray-700"
              >
                <input
                  v-model="dataPenjualan.faktur.metode"
                  @change="changeMetode"
                  type="radio"
                  name="metode_pengiriman"
                  value="delivery"
                  class="mr-1 h-4 w-4 cursor-pointer border-gray-300 text-gray-600 focus:ring-gray-500"
                  aria-labelledby="privacy-setting-1-label"
                  aria-describedby="privacy-setting-1-description"
                  :disabled="is_detail"
                />
                Dikirim
              </div>
            </div>
            <div
              v-if="
                dataPenjualan.faktur.metode === 'delivery' &&
                dataPenjualan.faktur.nama_pemilik
              "
              class="my-6"
            >
              <h3 class="text-sm font-semibold">Alamat Mitra Usaha</h3>
              <div
                v-if="dataPenjualan.faktur.destination_address.status"
                class="divide-y-2"
              >
                <p class="mt-2 mb-3 text-gray-600">
                  {{ dataPenjualan.faktur.destination_address.alamat }},
                  {{ dataPenjualan.faktur.destination_address.village.name }},
                  {{ dataPenjualan.faktur.destination_address.district.name }},
                  {{ dataPenjualan.faktur.destination_address.city.name }},
                  {{ dataPenjualan.faktur.destination_address.province.name }}
                </p>
                <div v class="py-3">
                  <button
                    type="button"
                    @click="openModalAddress"
                    class="bg-white-600 rounded-md border border-gray-300 py-2 px-4 text-xs text-gray-600 shadow-sm hover:bg-white hover:shadow-lg"
                  >
                    Ganti Alamat
                  </button>
                  <button
                    type="button"
                    @click="resetAddress"
                    class="bg-white-600 ml-2 rounded-md border border-gray-300 py-2 px-4 text-xs text-gray-600 shadow-sm hover:bg-white hover:shadow-lg"
                  >
                    Gunakan Alamat Lama
                  </button>
                </div>
              </div>
              <div v-else class="divide-y-2">
                <p class="mt-2 mb-3 text-gray-600">
                  {{ dataPenjualan.faktur.address }}
                </p>
                <div v class="py-3" v-if="!is_detail">
                  <button
                    type="button"
                    @click="openModalAddress"
                    class="bg-white-600 rounded-md border border-gray-300 py-2 px-4 text-xs text-gray-600 shadow-sm hover:bg-white hover:shadow-lg"
                  >
                    Pilih Alamat Lain
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <OrderDetailTable
      v-if="canAddProduct"
      class="mb-5"
      :create-order-payload="{
        order_type: 'sale',
        notes: dataPenjualan.faktur.notes,
        is_ppn_included: true,
        is_pre_order: false,
        is_prev_bonus: true,
        order_shipment: dataPenjualan.faktur.metode,
      }"
      :origin-warehouse="{
        id: dataPenjualan.faktur.id_gudang,
      }"
      :destination-warehouse="{
        id: dataPenjualan.faktur.id_gudang_stockist,
      }"
      :origin-office="{
        id: me.office_id,
        code: sellerOfficeCode,
      }"
      :destination-office="{
        id: dataPenjualan.faktur.id_member,
        code: dataPenjualan.faktur.kode_member,
      }"
      :buyer-type="{
        id: dataPenjualan.faktur.id_buyer_type,
        code: dataPenjualan.faktur.kode_buyer_type,
      }"
      :area="{
        id: dataPenjualan.faktur.id_wilayah_harga,
        code: dataPenjualan.faktur.wilayah_harga,
      }"
      :order-id="dataPenjualan.faktur.id"
      :min-grand-total="0"
      :columns="[
        'product_point',
        'total_point',
        'product_bonus',
        'total_bonus',
        'total_weight',
      ]"
      :stock-modal-columns="['pv', 'bv']"
      v-model="orderDetails"
      v-on:order-created="handleCreatedOrder"
      v-on:created="handleCreatedOrderDetail"
    />

    <template v-if="canAddProduct">
      <!-- Cashbill Summary -->
      <CashbillOrderSummary
        :total-pv="totalPointValue"
        :total-bv="totalBonusValue"
        :grand-total="totalPrice"
        :total-weight="totalWeight"
      />
    </template>

    <div
      v-if="orderDetails.length > 1"
      class="mb-5 rounded-lg bg-white p-4 sm:p-6 md:p-8"
    >
      <div class="pb-5">
        <h3 class="text-lg font-bold leading-6 text-gray-900">
          Metode Pembayaran
        </h3>
      </div>
      <PaymentMethodInlineForm
        :payment-id="getIncludedByType(getOrder, 'payments')[0].id"
        auto-store
        v-model="paymentMethodsForm"
      />
    </div>

    <div class="pt-5">
      <div class="flex justify-end">
        <!-- disable button -->
        <button
          v-if="totalPrice < 1"
          disabled
          class="ml-3 inline-flex rounded-md border border-gray-300 bg-gray-300 py-2 px-4 text-sm font-medium text-white shadow-sm"
        >
          Simpan
        </button>
        <button
          v-else
          @click="addConfirm"
          class="ml-3 inline-flex rounded-md border border-gray-300 bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-600 hover:shadow-lg"
        >
          Simpan
        </button>
        <router-link
          to="/cashbill/pencatatan-tambah-poin"
          class="ml-3 inline-flex rounded-md border border-gray-300 bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-600 hover:shadow-lg"
        >
          Selesai
        </router-link>
      </div>
    </div>

    <!-- Modal Confirmation -->
    <transition name="fade">
      <Modal2
        v-if="addStatus"
        @close-modal="addStatus = false"
        colorheading="bg-green-50"
        colorbody="bg-green-50"
      >
        <template v-slot:heading>
          <h3 class="text-lg font-bold leading-6 text-gray-900">
            Konfirmasi Tambah Penjualan
          </h3>
        </template>
        <template v-slot:body>
          <div
            class="flex relative content-center items-center justify-center py-2"
          >
            <svg
              class="text-green-100"
              width="194"
              height="191"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M108.028 1.515c26.059 5.163 34.467 35.206 50.246 56.51 14.48 19.55 37.067 35.218 35.664 59.477-1.648 28.473-17.071 57.204-43.101 69.053-25.607 11.655-53.413-2.016-79.137-13.413-27.257-12.077-58.095-23.354-67.512-51.561-10.255-30.716-.982-65.558 20.224-90.077C44.76 7.977 77.462-4.541 108.028 1.515Z"
                fill="currentColor"
              />
            </svg>
            <div class="flex absolute w-full justify-center">
              <DocumentSuccess class="h-32" />
            </div>
          </div>
          <p class="text-lg leading-6 text-gray-900" id="modal-title">
            Anda akan menambahkan penjualan baru. <br />
            Apakah Anda yakin data yang dibuat sudah benar?
          </p>
        </template>
        <template v-slot:footer>
          <div>
            <button
              @click="addOrder"
              class="focus:outline-none w-full shrink justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-sm"
            >
              Ya, saya yakin
            </button>
            <button
              @click="addStatus = !addStatus"
              class="bg-white-600 inline-flex w-full justify-center rounded-md px-4 py-2 text-base font-medium text-gray-400 hover:bg-white hover:text-blue-400 sm:text-sm"
            >
              Kembali
            </button>
          </div>
        </template>
      </Modal2>
    </transition>

    <!-- Modal Success -->
    <transition name="fade">
      <Modal2
        v-if="success"
        @close-modal="success = false"
        colorheading="bg-green-50"
        colorbody="bg-green-50"
      >
        <template v-slot:heading></template>
        <template v-slot:body>
          <div
            class="flex relative content-center items-center justify-center py-2"
          >
            <svg
              class="text-green-100"
              width="194"
              height="191"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M108.028 1.515c26.059 5.163 34.467 35.206 50.246 56.51 14.48 19.55 37.067 35.218 35.664 59.477-1.648 28.473-17.071 57.204-43.101 69.053-25.607 11.655-53.413-2.016-79.137-13.413-27.257-12.077-58.095-23.354-67.512-51.561-10.255-30.716-.982-65.558 20.224-90.077C44.76 7.977 77.462-4.541 108.028 1.515Z"
                fill="currentColor"
              />
            </svg>
            <div class="flex absolute w-full justify-center">
              <DocumentSuccess class="h-32" />
            </div>
          </div>
          <p class="text-lg leading-6 text-gray-900" id="modal-title">
            Data dengan No. Faktur
            <span class="font-bold">{{ dataPenjualan.faktur.no_invoice }}</span>
            berhasil ditambahkan.
          </p>
        </template>
        <template v-slot:footer>
          <div class="flex flex-col">
            <button
              @click="showDetailOrder"
              type="submit"
              class="inline-flex rounded-md border border-gray-300 bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-600 hover:shadow-lg"
            >
              Lihat Detail
            </button>
            <router-link
              to="/cashbill/pencatatan-tambah-poin"
              class="bg-white-600 inline-flex w-full justify-center rounded-md px-4 py-2 text-base font-medium text-gray-400 hover:bg-white hover:text-blue-400 sm:text-sm"
            >
              Kembali
            </router-link>
          </div>
        </template>
      </Modal2>
    </transition>

    <!-- Modal Detail Order -->
    <CashbillDetailModal
      :order="getOrder.data"
      :office="getOffice"
      :visible="isDetailOrderShown"
      :deleteable="false"
      :editable="false"
      @close="handleRedirect"
      @refresh="handleRedirect"
    />
    <!-- end of modal success -->

    <!-- modal stockist data -->
    <baseModal
      :showing="openListMember"
      @close="openListMember = false"
      :showClose="openListMember"
      size="max-w-7xl"
    >
      <div class="flex justify-between py-5">
        <div>
          <h3 class="text-lg font-bold leading-6 text-gray-900">
            Daftar Mitra Usaha
          </h3>
          <p class="mt-1 max-w-2xl text-sm text-gray-500">
            Berdasarkan data Mitra Usaha yang telah dibuat
          </p>
        </div>
        <div>
          <img src="@/assets/images/logo.png" alt="" class="w-52" />
        </div>
      </div>
      <div class="border-t border-gray-200 py-5">
        <div class="flex justify-end">
          <base-search
            v-model="search.member"
            @input="debounceSearchMember"
            placeholder="Cari kode atau nama"
          />
        </div>
        <div class="flex my-2 sm:-mx-6 lg:-mx-8">
          <div
            class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"
          >
            <Datatable
              :total="getMembers.meta.page.total"
              :perPage="getMembers.meta.page.perPage"
              :currentPage="getMembers.meta.page.currentPage"
              @pagechanged="onPagechangeMember"
              cursor
            >
              <template v-slot:thead>
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Nomor Tetap / Sementara
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Nama Mitra Usaha
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Upline
                    </th>
                  </tr>
                </thead>
              </template>
              <template v-slot:tbody>
                <tbody>
                  <tr
                    class="cursor-pointer bg-white hover:bg-green-100"
                    v-for="(data, dataIdx) in getMembers.data"
                    :key="data.id"
                    :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                    @click="changeMitraUsaha(getOfficeIncluded(data))"
                  >
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm font-bold text-gray-700"
                    >
                      {{ getOfficeIncluded(data).attributes.code }} /
                      {{ getOfficeIncluded(data).attributes.temp_code ?? '-' }}
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      {{ getOfficeIncluded(data).attributes.name }}
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      {{ getOfficeIncluded(data).attributes.referred_by_name }}
                      {{ getOfficeIncluded(data).attributes.referred_by }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </Datatable>
          </div>
        </div>
      </div>
      <div class="flex justify-end">
        <button
          @click="openListMember = !openListMember"
          type="button"
          class="focus:outline-none inline-flex justify-end rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-sm"
        >
          Tutup
        </button>
      </div>
    </baseModal>
    <!-- end of modal stockist -->

    <transition>
      <div
        v-if="openAddAddress"
        class="fixed inset-0 z-10 overflow-y-auto"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div
          class="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
        >
          <transition
            name="customFade"
            enter-active-class="fadeIn"
            leave-active-class="fadeOut"
            appear
          >
            <div
              class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>
            <span
              class="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
              >&#8203;</span
            >
          </transition>
          <div
            class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle"
          >
            <div class="flex justify-center px-4 py-5 sm:px-6">
              <div>
                <h3 class="text-lg font-bold leading-6 text-gray-900">
                  Tambah Alamat Pengiriman
                </h3>
                <div class="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    @click="openAddAddress = !openAddAddress"
                    class="focus:outline-none rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    <span class="sr-only">Close</span>
                    <!-- Heroicon name: outline/x -->
                    <svg
                      class="h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
              <div class="flex my-2 sm:-mx-6 lg:-mx-8">
                <div
                  class="inline-block min-w-full space-y-4 py-2 align-middle sm:px-2 lg:px-4"
                >
                  <div class="field-inset-default w-full">
                    <label
                      for="name"
                      class="block text-xs font-bold text-gray-700"
                    >
                      Provinsi
                    </label>
                    <select
                      v-model="
                        dataPenjualan.faktur.destination_address.province
                      "
                      @change="changeProvince"
                      class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                      placeholder="Masukkan Stok Barang"
                    >
                      <option :value="{ id: '', name: '' }">
                        Pilih Provinsi
                      </option>
                      <option
                        v-for="prov in getProvinces.data"
                        :key="prov.id"
                        :value="{
                          id: prov.id,
                          name: prov.attributes.name,
                        }"
                      >
                        {{ prov.attributes.name }}
                      </option>
                    </select>
                  </div>
                  <div class="field-inset-default w-full">
                    <label
                      for="name"
                      class="block text-xs font-bold text-gray-700"
                    >
                      Kabupaten
                    </label>
                    <select
                      v-model="dataPenjualan.faktur.destination_address.city"
                      @change="changeCity"
                      class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                      placeholder="Masukkan Stok Barang"
                    >
                      <option
                        :value="{
                          id: '',
                          name: '',
                          area: '',
                        }"
                      >
                        Pilih Kabupaten
                      </option>
                      <option
                        v-for="city in getCities.data"
                        :key="city.id"
                        :value="{
                          id: city.id,
                          name: city.attributes.name,
                          area: city.attributes.area_code,
                        }"
                      >
                        {{ city.attributes.name }}
                      </option>
                    </select>
                  </div>
                  <div class="field-inset-default w-full">
                    <label
                      for="name"
                      class="block text-xs font-bold text-gray-700"
                    >
                      Kecamatan
                    </label>
                    <select
                      v-model="
                        dataPenjualan.faktur.destination_address.district
                      "
                      @change="changeDistrict"
                      class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                      placeholder="Masukkan Stok Barang"
                    >
                      <option :value="{ id: '', name: '' }">
                        Pilih Kecamatan
                      </option>
                      <option
                        v-for="district in getDistricts.data"
                        :key="district.id"
                        :value="{
                          id: district.id,
                          name: district.attributes.name,
                        }"
                      >
                        {{ district.attributes.name }}
                      </option>
                    </select>
                  </div>
                  <div class="field-inset-default w-full">
                    <label
                      for="name"
                      class="block text-xs font-bold text-gray-700"
                    >
                      Desa
                    </label>
                    <select
                      v-model="dataPenjualan.faktur.destination_address.village"
                      class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                      placeholder="Masukkan Stok Barang"
                    >
                      <option :value="{ id: '', name: '' }">Pilih Desa</option>
                      <option
                        v-for="village in getVillages.data"
                        :key="village.id"
                        :value="{
                          id: village.id,
                          name: village.attributes.name,
                        }"
                      >
                        {{ village.attributes.name }}
                      </option>
                    </select>
                  </div>
                  <div class="field-inset-default w-full">
                    <label
                      for="alamat"
                      class="block text-xs font-bold text-gray-700"
                    >
                      Alamat
                    </label>
                    <input
                      v-model="dataPenjualan.faktur.destination_address.alamat"
                      type="text"
                      name="alamat"
                      id="alamat"
                      class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                      placeholder="Masukkan Alamat Suplier"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="flex w-full justify-center">
              <button
                @click="saveAddress"
                type="button"
                class="focus:outline-none block w-full rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-sm"
              >
                Simpan Alamat
              </button>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <!-- Modal Stock Barang -->
    <stock-table-modal
      :visible="openListProductByStockist"
      :params="{
        warehouseId: dataPenjualan.faktur.id_gudang,
        'filter[seller_office_code]': sellerOfficeCode,
        'filter[buyer_office_code]': selectedStockistCodeWithoutArea,
        'filter[area_code]': dataPenjualan.faktur.wilayah_harga,
        'filter[buyer_type_code]': dataPenjualan.faktur.kode_buyer_type,
      }"
      @close="() => (openListProductByStockist = !openListProductByStockist)"
      @click-product="changeProduct"
    />

    <!-- print -->
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :preview-modal="true"
      :enable-download="false"
      :paginate-elements-by-height="5000"
      :filename="dataPenjualan.faktur.no_invoice"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-format="a7"
      pdf-orientation="portrait"
      pdf-content-width="100mm"
      ref="html2Pdf"
    >
      <pdf-content slot="pdf-content" :order="getOrder" />
    </vue-html2pdf>

    <!-- Loader -->
    <loading v-if="loading.visible" :text="loading.text" />
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { StorageService } from '@/services/storage.service';
import dayjs from 'dayjs';
import { debounce } from 'debounce';
import Datatable from '@/components/base/Datatable';
import PdfContent from '@/layouts/LayoutPrint.vue';
import Modal2 from '@/components/Modal2.vue';
import baseModal from '@/components/base/BaseModal';
import VueHtml2pdf from 'vue-html2pdf';
import CashbillDetailModal from '@/components/cash-bill/CashbillDetailModal.vue';
import { PenjualanInterface, BarangInterface } from '@/interface';
import { DocumentSuccess } from '@/assets/icons';
import Loading from '@/components/Loading.vue';
import CashbillOrderSummary from '@/components/cash-bill/CashbillOrderSummary.vue';
import StockTableModal from '@/components/stock/StockTableModal.vue';
import OrderDetailTable from '@/components/order/create/OrderDetailTable.vue';
import PaymentMethodInlineForm from '@/components/payment/PaymentMethodInlineForm';
import { orderDetailsFormMixin } from '@/mixins/order/order-details-form.mixin';
import { paymentMethodFormMixin } from '@/mixins/payment-method/payment-method-form.mixin';
import { loadingMixin } from '@/mixins/loading/loading.mixin';
import BaseSearch from '@/components/base/Search.vue';

export default {
  name: 'TambahPenjualan',
  components: {
    Datatable,
    Modal2,
    baseModal,
    VueHtml2pdf,
    PdfContent,
    CashbillDetailModal,
    DocumentSuccess,
    Loading,
    CashbillOrderSummary,
    StockTableModal,
    OrderDetailTable,
    PaymentMethodInlineForm,
    BaseSearch,
  },
  mixins: [orderDetailsFormMixin, paymentMethodFormMixin, loadingMixin],
  data() {
    return {
      is_detail: false,
      addStatus: false,
      success: false,
      failed: false,
      openDetail: false,
      openListMember: false,
      openListGudang: false,
      openListProductByStockist: false,
      openAddAddress: false,

      isDetailOrderShown: false,

      cetakPdf: {
        cetak: false,
        download: false,
      },
      search: {
        member: '',
        gudang: '',
        product: '',
      },
      productIndex: null,
      error: null,
      sellerOfficeCode: null,

      // stockist area code
      stockistAreaCode: null,

      // detailPenjualan: null,
      dataPenjualan: {
        faktur: {
          id: null,
          canceled_from: null,
          no_invoice: null,
          tanggal: dayjs().format('YYYY-MM-DD'),
          id_buyer_type: null,
          kode_buyer_type: null,
          id_member: null,
          id_gudang_stockist: null,
          kode_member: null,
          area_member: null,
          nama_pemilik: null,
          no_upline: null,
          name_upline: null,
          id_gudang: null,
          kode_gudang: null,
          area_gudang: '0',
          id_wilayah_harga: null,
          wilayah_harga: null,
          metode: 'pickup',
          address: null,
          city: null,
          phone: null,
          notes: null,
          destination_address: {
            province: {
              id: '',
              name: '',
            },
            city: {
              id: '',
              name: '',
              area: '',
            },
            district: {
              id: '',
              name: '',
            },
            village: {
              id: '',
              name: '',
            },
            alamat: null,
            status: false,
          },
        },
        barang: [{ ...BarangInterface }],
      },
      metodePembayaran: [
        {
          jenisPembayaran: null,
          officeBank: null,
          nominal: null,
        },
      ],
      paymentMethods: [
        {
          name: 'Cash',
          value: 'cash',
        },
        {
          name: 'Fee',
          value: 'fee',
        },
        {
          name: 'Rabat',
          value: 'rabat',
        },
        {
          name: 'Uang Jalan',
          value: 'uang_jalan',
        },
        {
          name: 'Bank',
          value: 'bank',
        },
      ],
    };
  },
  async created() {
    this.startLoading('Memuat Faktur');

    try {
      await Promise.all([
        this.loadOfficeBanks(),
        this.setOriginWarehouse(),
        this.setOriginOffice(),
        this.$route.params.id
          ? this.setOrder(this.$route.params.id)
          : this.setBuyerType(),
      ]);
    } finally {
      this.stopLoading();
    }

    const handleEscape = () => {
      this.success = false;
      this.failed = false;
    };
    this.setEscape(handleEscape);
  },
  computed: {
    ...mapGetters({
      getPaymentMethods: 'payments/getPaymentMethods',
      getMembers: 'simple_offices/getUplineSimpleOffices',
      getOffice: 'offices/getOffice',
      getWarehouseByOffice: 'warehouses/getWarehouseByOffice',
      getWarehouseStock: 'warehouses/getWarehouseStock',
      getBanksByOffice: 'banks/getBanksByOffice',
      getProvinces: 'indonesia/getProvinces',
      getCities: 'indonesia/getCities',
      getDistricts: 'indonesia/getDistricts',
      getVillages: 'indonesia/getVillages',
      getStocks: 'stocks/getStocks',
      getOrder: 'orders/getOrder',
      getError: 'orders/getError',
      getLoading: 'orders/getLoading',
      memberLoading: 'members/getLoading',
      me: 'auth/getUser',
    }),
    canAddProduct() {
      return (
        this.dataPenjualan.faktur.kode_member !== '' &&
        this.dataPenjualan.faktur.kode_member !== null &&
        this.dataPenjualan.faktur.id_gudang &&
        this.dataPenjualan.faktur.wilayah_harga !== null
      );
    },
    selectedStockistCodeWithoutArea() {
      return this.dataPenjualan.faktur.kode_member;
    },
    selectedArea() {
      // kode area pusat yg login
      const userWarehouseAreaCode = this.dataPenjualan.faktur.area_gudang;
      // kode area alamat pengiriman atau kode area stockist
      const selectedStockistAreaCode = this.dataPenjualan.faktur
        .destination_address.status
        ? this.dataPenjualan.faktur.destination_address.city.area
        : this.dataPenjualan.faktur.area_stockist;

      if (
        selectedStockistAreaCode === undefined ||
        selectedStockistAreaCode === null
      ) {
        return null;
      }

      // area wilayah harga jika metode transaksi diambil gunakan area kp jika dikirim dibandingkan yang tertinggi
      if (this.dataPenjualan.faktur.metode === 'diambil') {
        return userWarehouseAreaCode;
      }

      return Math.max(userWarehouseAreaCode, selectedStockistAreaCode);
    },
    warehouseStockParams() {
      return {
        warehouseId: this.dataPenjualan.faktur.id_gudang,
        sellerOfficeCode: this.sellerOfficeCode,
        buyerOfficeCode: this.dataPenjualan.faktur.kode_member,
        areaCode: this.dataPenjualan.faktur.wilayah_harga,
        buyerTypeCode: this.dataPenjualan.faktur.kode_buyer_type,
        // 'filter[is_point]': true,
        pageNumber: 1,
        pageSize: 5,
      };
    },
    fetchMembersParams() {
      return {
        'filter[office_category_id]': 5,
        'fields[offices]':
          'temp_code,code,name,phone,address,is_active,referred_by,referred_by_name',
        'fields[simple-offices]': 'office',
        include: 'office',
        'filter[is_active]': true,
        'filter[is_draft]': false,
        'page[limit]': 5,
      };
    },
  },
  methods: {
    ...mapActions({
      fetchOfficesByCategory: 'offices/fetchOfficesByCategory',
      createAlert: 'alert/createAlert',
      fetchBuyerTypes: 'buyer_types/fetchBuyerTypes',
      fetchMembers: 'simple_offices/fetchSimpleOfficesByUpline',
      fetchMember: 'members/fetchMember',
      fetchBanksByOffice: 'banks/fetchBanksByOffice',
      fetchOfficeById: 'offices/fetchOfficeById',
      fetchWarehouseById: 'warehouses/fetchWarehouseById',
      fetchWarehouseByOffice: 'warehouses/fetchWarehouseByOffice',
      fetchWarehouseStock: 'warehouses/fetchWarehouseStock',
      fetchProvinces: 'indonesia/fetchProvinces',
      fetchCities: 'indonesia/fetchCities',
      fetchDistricts: 'indonesia/fetchDistricts',
      fetchVillages: 'indonesia/fetchVillages',
      fetchAreas: 'areas/fetchAreas',
      createOrder: 'orders/createOrder',
      fetchOrder: 'orders/fetchOrder',
      updateOrder: 'orders/updateOrder',
      validateOrder: 'orders/validate',
      createPayment: 'payments/createPayment',
      createPaymentMethod: 'payment_methods/createPaymentMethod',
      deletePaymentMethod: 'payment_methods/deletePaymentMethod',
      updatePaymentMethod: 'payment_methods/updatePaymentMethod',
      fetchPaymentPaymentMethods: 'payments/fetchPaymentPaymentMethods',
      fetchPaymentMethodTypesByOffice:
        'payment_method_types/fetchPaymentMethodTypesByOffice',
    }),
    handleCreatedOrder(order) {
      this.dataPenjualan.faktur.id = order.id;
      this.is_detail = true;

      this.$router.push(`/cashbill/pencatatan-tambah-poin/${order.id}`);
    },
    handleCreatedOrderDetail() {
      this.pushEmptyPaymentDetailsItem();
    },
    loadMembers(params = {}) {
      this.startLoading('Memuat Daftar Member');

      this.fetchMembers({
        uplineId: this.me.office_id,
        params: {
          ...this.fetchMembersParams,
          ...params
        },
      }).finally(() => this.stopLoading());
    },
    async loadOfficeBanks() {
      await Promise.all([
        this.fetchPaymentMethodTypesByOffice({
          officeId: StorageService.getUser().office_id,
          params: {
            'filter[is_reserved]': false,
          },
        }),
        this.fetchBanksByOffice({
          office_id: StorageService.getUser().office_id,
        }),
      ]);
    },
    async setOriginWarehouse() {
      const warehouse = await this.fetchWarehouseById({
        id: this.me.current_warehouse,
      });

      if (warehouse) {
        this.dataPenjualan.faktur.id_gudang = warehouse.data.data.id;
        this.dataPenjualan.faktur.kode_gudang =
          warehouse.data.data.attributes.code;
        this.dataPenjualan.faktur.nama_gudang =
          warehouse.data.data.attributes.name;
        this.dataPenjualan.faktur.area_gudang =
          warehouse.data.data.attributes.area_code;
        await this.updateArea();
      }
    },
    async setOriginOffice() {
      const response = await this.fetchOfficeById({
        office_id: StorageService.getUser().office_id,
        params: {
          include: 'area',
          'fields[offices]': 'code,area',
        },
      });

      this.sellerOfficeCode = response.data.data.attributes.code;
      this.dataPenjualan.faktur.wilayah_harga = this.getRelationships(
        response.data,
        response.data.data.relationships.area.data.id
      ).attributes.code;
    },
    async setBuyerType() {
      const response = await this.fetchBuyerTypes({
        keyword: 8, // default buyer type mitra usaha
      });
      this.dataPenjualan.faktur.id_buyer_type = response.data.data[0].id;
      this.dataPenjualan.faktur.kode_buyer_type =
        response.data.data[0].attributes.code;
    },
    getTotalBV() {
      return this.dataPenjualan.barang.reduce((total, barang) => {
        return total + barang.bonus_value * parseInt(barang.jumlah | 0);
      }, 0);
    },
    getTotalPV() {
      return this.dataPenjualan.barang.reduce((total, barang) => {
        return total + (barang.bonus_point | 0) * parseInt(barang.jumlah | 0);
      }, 0);
    },
    showDetailOrder() {
      this.isDetailOrderShown = true;
      this.success = false;
    },
    openModalMember() {
      this.openListMember = !this.openListMember;
      this.loadMembers({});
    },
    openModalProduct(index) {
      this.productIndex = index;
      this.openListProductByStockist = !this.openListProductByStockist;
      this.fetchWarehouseStock({
        ...this.warehouseStockParams,
      });
    },
    openModalAddress() {
      this.openAddAddress = !this.openAddAddress;
      this.fetchProvinces({
        pageNumber: 1,
      });
    },
    changeProvince() {
      this.fetchCities({
        province_id: this.dataPenjualan.faktur.destination_address.province.id,
        pageNumber: 1,
      });
      this.dataPenjualan.faktur.destination_address.city = {
        id: '',
        name: '',
        area: '',
      };
      this.dataPenjualan.faktur.destination_address.district =
        this.dataPenjualan.faktur.destination_address.village = {
          id: '',
          name: '',
        };
    },
    changeCity() {
      this.fetchDistricts({
        city_id: this.dataPenjualan.faktur.destination_address.city.id,
        pageNumber: 1,
      });
      this.dataPenjualan.faktur.destination_address.district =
        this.dataPenjualan.faktur.destination_address.village = {
          id: '',
          name: '',
        };
    },
    changeDistrict() {
      this.fetchVillages({
        district_id: this.dataPenjualan.faktur.destination_address.district.id,
        pageNumber: 1,
      });
      this.dataPenjualan.faktur.destination_address.village = {
        id: '',
        name: '',
      };
    },
    saveAddress() {
      this.dataPenjualan.faktur.destination_address.status = true;
      this.updateArea();
      this.openAddAddress = !this.openAddAddress;
    },
    resetAddress() {
      this.dataPenjualan.faktur.destination_address.status = false;
      this.updateArea();
    },
    successModal() {
      this.success = !this.success;
      this.clearData();
    },
    failedModal() {
      this.failed = !this.failed;
      this.clearData();
    },
    changeMitraUsaha(data) {
      this.dataPenjualan.faktur.kode_member = data.attributes.code;
      this.searchMember();
      this.openListMember = !this.openListMember;
    },
    changeGudang(data) {
      this.dataPenjualan.faktur.kode_gudang = data.attributes.code;
      this.searchGudang();
      this.openListGudang = !this.openListGudang;
    },
    onPagechangeMember(page) {
      this.loadMembers({
        ...page,
        search: this.search.member,
      });
    },
    onPagechangeGudang(page) {
      this.fetchWarehouseByOffice({
        pageNumber: page,
        pageSize: 5,
        search: this.search.gudang,
        userOfficeId: StorageService.getUser().office_id,
      });
    },
    onPageChangeProducts(page) {
      this.fetchWarehouseStock({
        ...this.warehouseStockParams,
        pageNumber: page,
      });
    },
    debounceSearchMember: debounce(function () {
      this.loadMembers({
        search: this.search.member,
      });
    }, 300),
    debounceSearchGudang: debounce(function () {
      this.fetchWarehouseByOffice({
        pageNumber: 1,
        pageSize: 5,
        search: this.search.gudang,
        userOfficeId: StorageService.getUser().office_id,
      });
    }, 300),
    debounceSearchProduct: debounce(function () {
      this.fetchWarehouseStock({
        ...this.warehouseStockParams,
      });
    }, 300),
    changeMetode() {
      this.updateArea();
      this.dataPenjualan.barang = [{ ...BarangInterface }];
    },
    async changeArea() {
      const response = await this.fetchAreas({
        keyword: this.dataPenjualan.faktur.wilayah_harga,
      });

      this.dataPenjualan.faktur.id_wilayah_harga = response.data.data[0].id;
    },
    changeProduct(data) {
      this.dataPenjualan.barang[this.productIndex].kode_barang =
        data.attributes.product_code;
      this.searchProduct(this.productIndex);
      this.openListProductByStockist = !this.openListProductByStockist;
      this.search.product = '';
    },
    async updateArea() {
      const areas = await this.fetchAreas({
        keyword: this.dataPenjualan.faktur.wilayah_harga,
      });

      this.dataPenjualan.faktur.id_wilayah_harga = areas.data.data[0].id;
    },
    async setOrder(id) {
      this.dataPenjualan.faktur.id = id;

      await this.fetchOrder({
        id: this.dataPenjualan.faktur.id,
      });

      const destinationOffice = this.getRelationships(
        this.getOrder,
        this.getOrder.data.relationships['destination-office'].data.id
      );

      this.is_detail = true;

      this.dataPenjualan.faktur.metode =
        this.getOrder.data.attributes.order_shipment;
      this.dataPenjualan.faktur.no_invoice = this.getOrder.data.attributes.code;
      this.dataPenjualan.faktur.canceled_from =
        this.getOrder.data.attributes.canceled_from;
      this.dataPenjualan.faktur.tanggal = dayjs(
        this.getOrder.data.attributes.updatedAt
      ).format('YYYY-MM-DD');
      this.dataPenjualan.faktur.id_buyer_type = this.getRelationships(
        this.getOrder,
        this.getOrder.data.relationships['buyer-type'].data.id
      ).id;
      this.dataPenjualan.faktur.kode_buyer_type = this.getRelationships(
        this.getOrder,
        this.getOrder.data.relationships['buyer-type'].data.id
      ).attributes.code;
      this.dataPenjualan.faktur.id_member = destinationOffice.id;
      this.setDestinationUpline(destinationOffice);
      this.dataPenjualan.faktur.id_gudang_stockist = this.getRelationships(
        this.getOrder,
        this.getOrder.data.relationships['destination-warehouse'].data.id
      ).id;
      this.dataPenjualan.faktur.kode_member = destinationOffice.attributes.code;
      this.dataPenjualan.faktur.area_member = parseInt(
        destinationOffice.attributes.area
      );
      this.dataPenjualan.faktur.nama_pemilik =
        destinationOffice.attributes.name;
      this.dataPenjualan.faktur.id_gudang = this.getRelationships(
        this.getOrder,
        this.getOrder.data.relationships['origin-warehouse'].data.id
      ).id;
      this.dataPenjualan.faktur.kode_gudang = this.getRelationships(
        this.getOrder,
        this.getOrder.data.relationships['origin-warehouse'].data.id
      ).attributes.code;
      this.dataPenjualan.faktur.area_gudang = parseInt(
        this.getRelationships(
          this.getOrder,
          this.getOrder.data.relationships['origin-warehouse'].data.id
        ).attributes.area_code
      );
      this.dataPenjualan.faktur.id_wilayah_harga = this.getRelationships(
        this.getOrder,
        this.getOrder.data.relationships['area'].data.id
      ).id;
      this.dataPenjualan.faktur.wilayah_harga = this.getRelationships(
        this.getOrder,
        this.getOrder.data.relationships['area'].data.id
      ).attributes.code;
      this.dataPenjualan.faktur.address = destinationOffice.attributes.address;
      this.getOrder.data.relationships['order-details'].data.forEach(
        (item, index) => {
          const product = this.getRelationships(this.getOrder, item.id);

          this.dataPenjualan.barang[index].id_barang =
            product.attributes.product_id;
          this.dataPenjualan.barang[index].kode_barang =
            product.attributes.product_code;
          this.searchProduct(index, product.attributes.product_qty);
          this.pushEmptyProductRows();
          this.dataPenjualan.total_barang++;
        }
      );

      await this.fetchPaymentPaymentMethods({
        id: this.getOrder.data.relationships['payments'].data[0].id,
        include: 'office-bank',
      });

      // const payments = response.data.data;
      // this.setPaymentDetailsdd(payments);

      this.setOrderDetails();
      this.setPaymentDetails();
    },
    async searchMember() {
      if (this.dataPenjualan.faktur.kode_member) {
        this.startLoading('Membuat data member');

        const res = await this.fetchMembers({
          uplineId: this.me.office_id,
          params: {
            'fields[offices]':
              'code,name,phone,address,area,current-warehouse,referred_by,referred_by_name',
            include: 'office.area,office.current-warehouse',
            'filter[is_active]': true,
            'filter[is_draft]': false,
            'page[limit]': 1,
            'pagination[type]': 'cursor',
            'filter[code]': this.dataPenjualan.faktur.kode_member,
            'filter[office_category_id]': 5
          },
        });

        if (!res?.data?.data?.length) {
          this.createAlert({
            data: 'Mitra tidak ditemukan',
            type: 'warning',
          });
        } else {
          const member = this.getSingleIncluded(res.data, res.data.data[0].relationships.office.data.id);

          this.dataPenjualan.faktur.metode = 'pickup';
          this.dataPenjualan.faktur.id_member = member.id;
          this.dataPenjualan.faktur.nama_pemilik = member.attributes.name;
          this.dataPenjualan.faktur.area_member = this.getRelationships(
            res.data,
            member.relationships.area.data.id
          ).attributes.code;
          this.dataPenjualan.faktur.address = member.attributes.address;
          this.dataPenjualan.faktur.phone = member.attributes.phone;

          try {
            const warehouse = this.getRelationships(
              res.data,
              member.relationships['current-warehouse'].data.id
            );
            this.dataPenjualan.faktur.id_gudang_stockist = warehouse.id;
          } catch (err) {
            this.createAlert({
              data: `Gudang Mitra tidak ditemukan ${JSON.stringify(err)}`,
              type: 'warning',
            });
          }

          await this.setDestinationUpline(member);
          await this.changeArea();
        }

        this.stopLoading();
      }
    },
    searchGudang() {
      if (this.dataPenjualan.faktur.kode_gudang) {
        this.fetchWarehouseByOffice({
          keyword: this.dataPenjualan.faktur.kode_gudang,
          userOfficeId: StorageService.getUser().office_id,
        }).then(() => {
          if (this.getWarehouseByOffice.data.length > 0) {
            this.dataPenjualan.faktur.id_gudang =
              this.getWarehouseByOffice.data[0].id;
            this.dataPenjualan.faktur.kode_gudang =
              this.getWarehouseByOffice.data[0].attributes.code;
            this.dataPenjualan.faktur.nama_gudang =
              this.getWarehouseByOffice.data[0].attributes.name;
            this.dataPenjualan.faktur.area_gudang =
              this.getWarehouseByOffice.data[0].attributes.area_code;
            this.updateArea();
          } else {
            this.dataPenjualan.faktur.id_gudang = null;
            this.dataPenjualan.faktur.kode_gudang = null;
            this.dataPenjualan.faktur.nama_gudang = null;
            this.dataPenjualan.faktur.area_gudang = null;
          }
        });
      }
    },
    searchProduct(index, product_qty) {
      if (this.dataPenjualan.barang[index].kode_barang) {
        this.fetchWarehouseStock({
          ...this.warehouseStockParams,
          productCode: this.dataPenjualan.barang[index].kode_barang,
        }).then((res) => {
          if (
            this.getWarehouseStock.data.length > 0 &&
            this.productExists(this.dataPenjualan.barang[index].kode_barang)
          ) {
            if (this.getWarehouseStock.data[0].attributes.is_orderable) {
              this.dataPenjualan.barang[index].nama_barang =
                this.getWarehouseStock.data[0].attributes.product_name;
              this.dataPenjualan.barang[index].stock =
                this.getWarehouseStock.data[0].attributes.orderable_stock;
              this.dataPenjualan.barang[index].is_point =
                !!this.getWarehouseStock.data[0].attributes.is_point;
              this.dataPenjualan.barang[index].status =
                this.getWarehouseStock.data[0].attributes.is_orderable;
              this.dataPenjualan.barang[index].jumlah = product_qty || 1;
              this.dataPenjualan.barang[index].harga =
                this.getWarehouseStock.data[0].attributes.product_price;
              this.dataPenjualan.barang[index].total_harga =
                this.dataPenjualan.barang[index].harga *
                this.dataPenjualan.barang[index].jumlah;
              this.dataPenjualan.barang[index].berat = parseFloat(
                this.getWarehouseStock.data[0].attributes.stock_weight
              );
              this.dataPenjualan.barang[index].total_berat =
                parseFloat(
                  this.getWarehouseStock.data[0].attributes.stock_weight
                ) * this.dataPenjualan.barang[index].jumlah;
              this.dataPenjualan.barang[index].data = res.data;
              if (
                this.dataPenjualan.barang[this.dataPenjualan.barang.length - 1]
                  .kode_barang
              ) {
                this.pushEmptyProductRows();
                this.dataPenjualan.total_barang++;
              }
              // pv & bv
              this.dataPenjualan.barang[index].bonus_point =
                this.getWarehouseStock.data[0].attributes.bonus_point;
              this.dataPenjualan.barang[index].bonus_value =
                this.getWarehouseStock.data[0].attributes.bonus_value;
              this.error = null;
            } else {
              this.dataPenjualan.barang[index].status =
                this.getWarehouseStock.data[0].attributes.status_order;
              this.dataPenjualan.barang[index].nama_barang =
                this.getWarehouseStock.data[0].attributes.product_name;
              this.dataPenjualan.barang[index].stock =
                this.getWarehouseStock.data[0].attributes.orderable_stock;
              this.dataPenjualan.barang[index].total_harga = null;
              this.dataPenjualan.barang[index].total_berat = null;
              this.error = `Tidak ada stock ${this.dataPenjualan.barang[index].kode_barang} yang tersedia`;
            }
          } else {
            this.dataPenjualan.barang[index].kode_barang = null;
            this.dataPenjualan.barang[index].nama_barang = null;
            this.dataPenjualan.barang[index].stock = null;
            this.dataPenjualan.barang[index].is_point = null;
            this.dataPenjualan.barang[index].jumlah = null;
            this.dataPenjualan.barang[index].harga = null;
            this.dataPenjualan.barang[index].berat = null;
            this.dataPenjualan.barang[index].total_harga = null;
            this.dataPenjualan.barang[index].total_berat = null;
            this.error = null;
          }
        });
      }
    },
    changeTotal(index, e) {
      // this.dataPenjualan.barang[index].jumlah = event.target.value;
      this.dataPenjualan.barang[index].total_harga =
        this.dataPenjualan.barang[index].jumlah *
        this.dataPenjualan.barang[index].harga;
      this.dataPenjualan.barang[index].total_berat =
        this.dataPenjualan.barang[index].jumlah *
        this.dataPenjualan.barang[index].berat;

      const value = Number(e.target.value.split(',')[0].replace(/\D/gi, ''));

      this.dataPenjualan.barang[index].jumlah = value;
      e.target.value = this.$options.filters.toCurrency(value, {
        style: 'decimal',
        maxFractionDigit: 0,
      });
    },
    getGrandTotalPrice() {
      return this.dataPenjualan.barang.reduce(function (total, attributes) {
        return total + (attributes.total_harga | 0);
      }, 0);
    },
    getGrandTotalWeight() {
      return this.dataPenjualan.barang.reduce(function (total, attributes) {
        return total + attributes.total_berat;
      }, 0);
    },
    addFormProduct(index) {
      if (
        this.dataPenjualan.barang[index].jumlah <
        this.dataPenjualan.barang[index].stock
      ) {
        if (
          this.dataPenjualan.barang[this.dataPenjualan.barang.length - 1]
            .kode_barang &&
          this.dataPenjualan.barang[index].status
        ) {
          this.pushEmptyProductRows();
          this.error = null;
        }
      } else {
        // this.error = `Jumlah barang ${this.dataPenjualan.barang[index].kode_barang} melebihi limit`
        this.dataPenjualan.barang[index].jumlah =
          this.dataPenjualan.barang[index].stock;
        this.dataPenjualan.barang[index].total_harga =
          this.dataPenjualan.barang[index].jumlah *
          this.dataPenjualan.barang[index].harga;
        this.dataPenjualan.barang[index].total_berat =
          this.dataPenjualan.barang[index].jumlah *
          this.dataPenjualan.barang[index].berat;
      }
    },
    getOrderDetails(params) {
      let dataDetail = [];
      params.map((param) => {
        let data = {
          product_code: param.kode_barang,
          product_qty: param.jumlah,
        };
        if (data.product_code && data.product_qty) dataDetail.push(data);
      });
      return dataDetail;
    },
    addConfirm() {
      this.addStatus = !this.addStatus;
    },
    addOrder() {
      const payload = {
        data: {
          type: 'orders',
          attributes: {
            order_type: 'sale',
            notes: this.dataPenjualan.faktur.notes,
            is_ppn_included: true,
            is_pre_order: false,
            order_shipment: this.dataPenjualan.faktur.metode,
            origin_warehouse_id: this.dataPenjualan.faktur.id_gudang,
            destination_warehouse_id:
              this.dataPenjualan.faktur.id_gudang_stockist,
            origin_office_id: StorageService.getUser().office_id,
            destination_office_id: this.dataPenjualan.faktur.id_member,
            details: this.getOrderDetails(this.dataPenjualan.barang),
            // payment_details: this.getDataDetails(this.metodePembayaran),
          },
          relationships: {
            'buyer-type': {
              data: {
                type: 'buyer-types',
                id: this.dataPenjualan.faktur.id_buyer_type,
              },
            },
            area: {
              data: {
                type: 'areas',
                id: this.dataPenjualan.faktur.id_wilayah_harga,
              },
            },
          },
        },
      };
      if (this.dataPenjualan.faktur.destination_address.status) {
        payload.data.attributes.destination_address = {
          province_id:
            this.dataPenjualan.faktur.destination_address.province.id,
          city_id: this.dataPenjualan.faktur.destination_address.city.id,
          district_id:
            this.dataPenjualan.faktur.destination_address.district.id,
          village_id: this.dataPenjualan.faktur.destination_address.village.id,
        };
      }
      if (this.is_detail) {
        this.validate();
      } else {
        this.createOrder(payload).then((createOrderResponse) => {
          if (createOrderResponse) {
            this.createPaymentMethods({
              orderResponse: createOrderResponse,
            }).then((createPaymentMethodsResponse) => {
              if (createPaymentMethodsResponse) {
                this.dataPenjualan.faktur.id = createOrderResponse.data.data.id;
                this.dataPenjualan.faktur.no_invoice = `${createOrderResponse.data.data.attributes.origin_code}/${createOrderResponse.data.data.attributes.destination_code}`;

                this.addStatus = false;
                this.fetchOrder({ id: this.dataPenjualan.faktur.id }).then(
                  () => {
                    this.isDetailOrderShown = true;

                    if (this.cetakPdf.cetak || this.cetakPdf.download) {
                      setTimeout(() => {
                        this.$refs.html2Pdf.generatePdf();
                      }, 0);
                    }
                  }
                );
              }
            });
          }
        });
      }
    },
    async createPaymentMethods({ orderResponse }) {
      if (orderResponse) {
        // Create Payment Methods
        const batchRequests = this.metodePembayaran.map((method) => {
          const officeBankByType = this.officeBanks.filter(
            (officeBank) =>
              officeBank.attributes.office_bank_type ===
              method.jenisPembayaran?.value
          );
          return this.createPaymentMethod({
            paymentId:
              orderResponse.data.data.relationships.payments.data[0].id,
            officeBankId: method.officeBank
              ? method.officeBank.id
              : officeBankByType[0]?.id ?? null,
            attributes: {
              payment_amount: method.nominal ?? 0,
              transfer_date: method.transfer_date
                ? dayjs(method.transfer_date).format()
                : null,
              ...(method.jenisPembayaran?.value === 'stockist'
                ? { balance_used: 'stockist' }
                : {}),
            },
          });
        });

        return await Promise.all(batchRequests);
      }
    },
    async validate() {
      this.startLoading('Menyimpan Faktur');

      const success = await this.validateOrder({
        id: this.dataPenjualan.faktur.id,
      });

      if (success) {
        const order = await this.fetchOrder({
          id: this.dataPenjualan.faktur.id,
        });

        if (order) {
          this.addStatus = false;
          this.isDetailOrderShown = true;
        }
      } else {
        this.failed = true;
      }

      this.stopLoading();
    },
    onChangeNominalPembayaran(e, index) {
      const value = Number(e.target.value.split(',')[0].replace(/\D/gi, ''));

      this.metodePembayaran[index].nominal = value;
      e.target.value = this.$options.filters.toCurrency(value, {
        style: 'decimal',
        maxFractionDigit: 0,
      });
    },
    onBlurNominalPembayaran({ index }) {
      const paymentId = this.getOrder.data.relationships.payments?.data[0].id;
      // payment dibuat ketika create doang
      if (!paymentId || !this.$route.params.id) return;
      const method = this.metodePembayaran[index];
      const officeBankByType = this.officeBanks.filter(
        (officeBank) =>
          officeBank.attributes.office_bank_type ===
          method.jenisPembayaran.value
      );
      const { attributes, officeBankId } = {
        officeBankId: method.officeBank
          ? method.officeBank.id
          : officeBankByType[0]?.id ?? null,
        attributes: {
          payment_amount: method.nominal ?? 0,
          transfer_date: method.transfer_date
            ? dayjs(method.transfer_date).format()
            : null,
          ...(method.jenisPembayaran.value === 'stockist'
            ? { balance_used: 'stockist' }
            : {}),
        },
      };
      if (method.paymentMethod) {
        // update
        this.updatePaymentMethod({
          paymentId,
          paymentMethodId: method.paymentMethod.id,
          officeBankId,
          attributes,
        }).then((response) => {
          this.metodePembayaran[index].paymentMethod = response.data.data;
        });
      } else {
        // create
        this.createPaymentMethod({ paymentId, officeBankId, attributes }).then(
          (response) => {
            this.metodePembayaran[index].paymentMethod = response.data.data;
          }
        );
      }
    },
    handleChangeMethod(index) {
      this.metodePembayaran[index].officeBank = null;
      this.metodePembayaran[index].transfer_date =
        dayjs().format('YYYY-MM-DDTHH:mm');

      if (this.metodePembayaran[index].jenisPembayaran.value == 'bank') {
        this.fetchBanksByOffice({
          office_id: StorageService.getUser().office_id,
          type: this.metodePembayaran[index].jenisPembayaran.value,
          isActive: true,
        });
      }
    },
    handleRedirect() {
      this.$router.push('/cashbill/pencatatan-tambah-poin');
    },
    getDataDetails(params) {
      let dataDetail = [];
      params.map((param) => {
        const officeBankByType = this.officeBanks.filter(
          (officeBank) =>
            officeBank.attributes.office_bank_type ===
            param.jenisPembayaran.value
        );
        let data = {
          office_bank_id: param.officeBank
            ? param.officeBank.id
            : officeBankByType[0]?.id ?? null,
          payment_amount: parseInt(param.nominal),
          is_using_balance: false,
        };
        dataDetail.push(data);
      });
      return dataDetail;
    },

    addField() {
      this.metodePembayaran.push({
        jenisPembayaran: null,
        officeBank: null,
        nominal: null,
      });
    },
    resetOfficeBank(index) {
      this.metodePembayaran[index].officeBank = null;
    },
    removeField(index) {
      const paymentMethodId = this.metodePembayaran[index].paymentMethod?.id;
      if (!paymentMethodId) {
        this.metodePembayaran.splice(index, 1);
        return;
      }
      this.deletePaymentMethod({ paymentMethodId }).then(() => {
        this.metodePembayaran.splice(index, 1);
      });
    },
    removeProduct(index) {
      this.dataPenjualan.barang.splice(index, 1);
    },
    productExists(code) {
      const data = this.dataPenjualan.barang.filter(function (el) {
        return el.kode_barang === code;
      });
      if (data.length > 1) return false;
      else return true;
    },
    getRelationships(resource, id) {
      let data = resource?.included?.filter(function (el) {
        return el.id === id;
      });
      return data?.[0];
    },
    clearData() {
      this.dataPenjualan = PenjualanInterface;
    },
    print() {
      this.$refs.html2Pdf.generatePdf();
    },
    pushEmptyProductRows: function () {
      this.dataPenjualan.barang.push({
        id_barang: null,
        kode_barang: null,
        nama_barang: null,
        stock: null,
        status: true,
        is_point: null,
        jumlah: null,
        harga: null,
        berat: null,
        total_harga: null,
        total_berat: null,
      });
    },
    epushEmptyPaymentDetailsItem: function () {
      this.metodePembayaran.push({
        jenisPembayaran: null,
        officeBank: null,
        nominal: 0,
      });
    },
    setPaymentDetailsdd: function () {
      this.metodePembayaran.length = 0;

      this.getPaymentMethods.data.forEach((paymentMethod) => {
        const paymentOfficeBank = this.getRelationships(
          this.getPaymentMethods,
          paymentMethod.relationships['office-bank'].data?.id
        );
        if (paymentOfficeBank) {
          this.metodePembayaran.push({
            officeBank: {
              id: paymentOfficeBank.id,
            },
            jenisPembayaran: this.paymentMethods.find(
              (item) =>
                item.value === paymentOfficeBank.attributes.office_bank_type
            ),
            paymentMethod,
            nominal: paymentMethod.attributes.payment_amount,
            transfer_date: paymentMethod.attributes.transfer_date,
          });
        } else {
          this.epushEmptyPaymentDetailsItem();
        }
      });
      setTimeout(() => {
        [...document.getElementsByClassName('nominal')].forEach(
          (item, index) => {
            item.value = this.$options.filters.toCurrency(
              this.metodePembayaran[index].nominal,
              {
                style: 'decimal',
                maxFractionDigit: 0,
              }
            );
          }
        );
      }, 0);
    },
    setDestinationUpline: async function (office) {
      this.dataPenjualan.faktur.no_upline = office.attributes.referred_by;
      this.dataPenjualan.faktur.name_upline =
        office.attributes.referred_by_name;
    },
    onChangeMember(e) {
      this.dataPenjualan.faktur.kode_member = e.target.value
        .split('/')[0]
        .trim();
    },
    memberCode() {
      if (!this.getMembers.data.length) {
        return '';
      }

      const office = this.getOfficeIncluded(this.getMembers.data[0]);

      if (!office?.attributes.temp_code) {
        return this.dataPenjualan.faktur.kode_member;
      }
      if (!this.dataPenjualan.faktur.kode_member) {
        return '';
      }
      return `${this.dataPenjualan.faktur.kode_member} / ${office?.attributes.temp_code}`;
    },
    getOfficeIncluded(simpleOffice) {
      return this.getSingleIncluded(
        this.getMembers,
        simpleOffice.relationships.office.data.id
      );
    },
  },
};
</script>
