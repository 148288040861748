<template>
  <base-card title="Tambah Faktur Pembayaran">
    <template #action>
      <base-button color="white" @click="onCreatePaymentMethod"
        >Tambah Metode</base-button
      >
    </template>
    <div class="space-y-4">
      <div class="space-y-4">
        <div
          v-for="(payment, index) in paymentMethods"
          :key="index"
          class="relative flex gap-4"
        >
          <base-input inset fullwidth with-label label="Metode Pembayaran">
            <base-select
              inset
              :options="paymentMethodTypesOptions"
              v-model="payment.paymentMethodTypeId"
              @change="onChangePaymentMethodTypeId(index)"
            />
          </base-input>
          <base-input
            v-if="checkInputBankVisible(index)"
            inset
            fullwidth
            with-label
            label="Pilih Bank"
          >
            <base-select
              inset
              :options="officeBanksTypeBankOptions"
              v-model="payment.officeBankId"
              @change="onChangeOfficeBankId(index)"
            />
          </base-input>
          <base-input
            v-if="checkInputReceiptVisible(index)"
            type="text"
            placeholder="Nomor Struk"
            fullwidth
            with-label
            label="Nomor Struk"
            inset
            v-model="payment.receiptNumber"
          />
          <base-input
            v-if="checkInputAmountVisible(index)"
            type="text"
            placeholder="Nominal Pembayaran"
            fullwidth
            with-label
            label="Nominal Pembayaran"
            inset
            currency
            v-model="payment.amount"
          />
          <button
            v-if="paymentMethods.length > 1"
            @click="onRemovePaymentMethod(index)"
            type="button"
            class="focus:outline-none absolute inset-y-0 -top-2 -right-2 flex h-5 w-5 items-center rounded-full border border-transparent bg-red-300 p-1 text-white shadow-sm hover:bg-red-500 focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-3 w-3"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              />
            </svg>
          </button>
        </div>
      </div>
      <hr />
      <div class="grid grid-cols-2 gap-4">
        <base-input
          inset
          disabled
          with-label
          label="Total Pembayaran"
          :value="totalAmount | toCurrency"
        />
        <base-input
          inset
          disabled
          with-label
          :label="totalRemainder > 0 ? 'Kekurangan' : 'Kelebihan'"
          :value="Math.abs(totalRemainder) | toCurrency"
        />
      </div>
      <div class="flex justify-end">
        <base-button v-if="canSave" @click="onSave">Validasi</base-button>
      </div>
    </div>
  </base-card>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import { mapGetters } from 'vuex';

export default {
  mixins: [requestMixin],
  props: {
    minAmount: {
      type: Number,
      default: 0,
    },
    paid: {
      type: Number,
      default: 0,
    },
  },
  emits: ['save'],
  data() {
    return {
      paymentMethodTypes: {
        data: [],
      },
      officeBanks: {
        data: [],
      },
      paymentMethods: [
        {
          paymentMethodTypeId: null,
          amount: null,
          officeBankId: null,
          receiptNumber: null,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      me: 'auth/getUser',
    }),
    canSave() {
      return this.paymentMethods.some(
        (paymentMethod) => paymentMethod.paymentMethodTypeId
      );
    },
    hasBank() {
      return this.officeBanks.data.filter(
        (bank) => bank.attributes.office_bank_type === 'bank'
      ).length;
    },
    officeBanksTypeBankOptions() {
      return [
        { key: 'null', value: null, label: 'Pilih Bank' },
        ...this.officeBanks.data
          .filter(
            (officeBank) => officeBank.attributes.office_bank_type === 'bank'
          )
          .map((officeBank) => {
            return {
              key: officeBank.id,
              value: officeBank.id,
              label: `${officeBank.attributes.bank_name} (a.n ${officeBank.attributes.account_name}) (${officeBank.attributes.account_number})`,
            };
          }),
      ];
    },
    paymentMethodTypesOptions() {
      return [
        { key: 'null', value: null, label: 'Pilih Metode Pembayaran' },
        ...this.paymentMethodTypes.data.map((type) => {
          const requireBank = this.checkIsPaymentMethodTypeRequireBank(type);
          const disabled = requireBank && !this.hasBank;

          return {
            key: type.id,
            value: type.id,
            label: disabled
              ? `${type.attributes.name} (Belum ada bank yang ditambahkan)`
              : type.attributes.name,
            disabled,
          };
        }),
      ];
    },
    totalAmount() {
      return this.paymentMethods
        .filter((method) => method.paymentMethodTypeId && method.amount)
        .reduce(
          (total, method) => total + Number(method.amount.replace(/\D/gi, '')),
          0
        );
    },
    totalRemainder() {
      return this.minAmount - (this.totalAmount + this.paid);
    },
  },
  methods: {
    checkInputAmountVisible(index) {
      const paymentMethodTypeId =
        this.paymentMethods[index].paymentMethodTypeId;

      if (!paymentMethodTypeId) {
        return false;
      }

      if (
        this.checkIsPaymentMethodTypeRequireBank(paymentMethodTypeId) &&
        !this.paymentMethods[index].officeBankId
      ) {
        return false;
      }

      if (
        this.checkIsPaymentMethodTypeRequireReceipt(paymentMethodTypeId) &&
        !this.paymentMethods[index].receiptNumber
      ) {
        return false;
      }

      return true;
    },
    checkInputBankVisible(index) {
      const paymentMethodTypeId =
        this.paymentMethods[index].paymentMethodTypeId;

      return (
        paymentMethodTypeId &&
        this.checkIsPaymentMethodTypeRequireBank(paymentMethodTypeId)
      );
    },
    checkInputReceiptVisible(index) {
      const paymentMethodTypeId =
        this.paymentMethods[index].paymentMethodTypeId;

      if (!paymentMethodTypeId) {
        return false;
      }

      if (
        !this.checkIsPaymentMethodTypeRequireBank(paymentMethodTypeId) ||
        !this.paymentMethods[index].officeBankId
      ) {
        return false;
      }

      if (!this.checkIsPaymentMethodTypeRequireReceipt(paymentMethodTypeId)) {
        return false;
      }

      return true;
    },
    checkIsPaymentMethodTypeRequireBank(paymentMethodTypeId) {
      const paymentMethodType =
        typeof paymentMethodTypeId === 'object'
          ? paymentMethodTypeId
          : this.paymentMethodTypes.data.find(
              (paymentMethodType) =>
                paymentMethodType.id === paymentMethodTypeId
            );

      return ['Transfer Bank', 'EDC'].includes(
        paymentMethodType.attributes.name
      );
    },
    checkIsPaymentMethodTypeRequireReceipt(paymentMethodTypeId) {
      const paymentMethodType =
        typeof paymentMethodTypeId === 'object'
          ? paymentMethodTypeId
          : this.paymentMethodTypes.data.find(
              (paymentMethodType) =>
                paymentMethodType.id === paymentMethodTypeId
            );

      return ['EDC'].includes(paymentMethodType.attributes.name);
    },
    async loadOfficeBanks() {
      const [res, error] = await this.request(
        `/api/v1/offices/${this.me.office_id}/office-banks`,
        {
          params: {
            'fields[office-banks]':
              'account_name,account_number,bank_name,office_bank_type',
          },
        }
      );

      if (!error) {
        this.officeBanks = res;
      }
    },
    async loadPaymentMethodTypes() {
      const [res, error] = await this.request(
        `/api/v1/offices/${this.me.office_id}/payment-method-types`,
        {
          params: {
            'filter[is_reserved]': false,
            'fields[payment-method-types]': 'name',
          },
        }
      );

      if (!error) {
        this.paymentMethodTypes = res;
      }
    },
    onChangeOfficeBankId(index) {
      if (!this.checkInputAmountVisible(index)) {
        this.paymentMethods[index].amount = null;
      }

      if (!this.checkInputReceiptVisible(index)) {
        this.paymentMethods[index].receiptNumber = null;
      }
    },
    onChangePaymentMethodTypeId(index) {
      if (!this.paymentMethods[index].paymentMethodTypeId) {
        this.paymentMethods[index].amount = null;
      }

      if (!this.checkInputBankVisible(index)) {
        this.paymentMethods[index].officeBankId = null;
      }

      if (!this.checkInputReceiptVisible(index)) {
        this.paymentMethods[index].receiptNumber = null;
      }
    },
    onCreatePaymentMethod() {
      this.paymentMethods.push({
        paymentMethodTypeId: null,
        amount: null,
        officeBankId: null,
        receiptNumber: null,
      });
    },
    onRemovePaymentMethod(index) {
      this.paymentMethods.splice(index, 1);
    },
    onSave() {
      this.$emit('save', this.paymentMethods);
    },
  },
  created() {
    this.loadOfficeBanks();
    this.loadPaymentMethodTypes();
  },
};
</script>
