<template>
  <div>
    <base-modal
      :showing="visible"
      size="max-w-5xl"
      @opened="onOpened"
      @close="$emit('close')"
    >
      <div class="space-y-6">
        <div class="flex items-center justify-between">
          <div class="flex items-center space-x-4">
            <div>
              <h3 class="text-lg font-bold leading-6 text-gray-900">
                Daftar Barang
              </h3>
              <p class="mt-1 max-w-2xl text-sm text-gray-500">
                Daftar Barang Yang Telah Ditambahkan
              </p>
            </div>
          </div>
        </div>
        <div class="space-y-4 border-t border-gray-200 pt-6">
          <div class="flex gap-x-2 justify-end">
            <base-select
              :shadow="false"
              :expand="false"
              :options="[
                { id: 'all', value: null, label: 'Semua' },
                { id: 'on_tock', value: true, label: 'Ada Stock' }
              ]"
              v-model="filter.has_stock_real"
              @change="loadStocks"
            />
            <base-input
              placeholder="Cari Barang"
              type="search"
              :shadow="false"
              debounce
              v-model="filter.search"
              @native-input="loadStocks"
            />
          </div>
          <datatable
            :columns="columns"
            :scroll-x="false"
            :total="stocks.meta.page.total"
            :perPage="stocks.meta.page.perPage"
            :currentPage="stocks.meta.page.currentPage"
            @pagechanged="onPagechanged"
          >
            <template v-slot:tbody="{ classes }">
              <tr
                v-for="stock in stocks.data"
                :key="stock.id"
                :class="[
                  classes.tr,
                  stock.attributes.stock_real > 0 ? 'cursor-pointer bg-white hover:bg-green-100' : 'bg-gray-50',
                ]"
                @click="onClickStock(stock)"
              >
                <td :class="[classes.td, 'font-bold text-gray-900']">
                  {{ stock.attributes.product_code }}
                </td>
                <td :class="[classes.td]">
                  {{ stock.attributes.product_name }}
                </td>
                <td :class="[classes.td]">
                  {{ stock.attributes.product_category_name }}
                </td>
                <td :class="[classes.td]">
                  {{ stock.attributes.stock_real }}
                </td>
              </tr>
            </template>
          </datatable>
        </div>
      </div>
    </base-modal>
    <loading v-if="loading" />
  </div>
</template>

<script>
import BaseModal from '@/components/base/BaseModal';
import { requestMixin } from '@/mixins/request/request';
import { mapGetters } from 'vuex';

export default {
  components: {
    BaseModal,
  },
  mixins: [requestMixin],
  props: {
    visible: Boolean,
    requestParams: null,
  },
  emits: ['close', 'click-stock'],
  data() {
    return {
      stocks: {
        data: [],
        meta: {
          page: {},
        },
      },
      loading: false,
      filter: {
        search: null,
        has_stock_real: true
      },
    };
  },
  computed: {
    ...mapGetters({
      me: 'auth/getUser'
    }),
    columns() {
      return [
        {
          id: 'code',
          name: 'Kode Barang',
        },
        {
          id: 'name',
          name: 'Nama Barang',
        },
        {
          id: 'category',
          name: 'Kelompok Barang',
        },
        {
          id: 'stock',
          name: 'Stock',
        },
      ];
    },
  },
  methods: {
    async loadStocks(params) {
      this.loading = true;

      const [res, error] = await this.request(`/api/v1/warehouses/${this.me.current_warehouse}/stocks`, {
        params: {
          ...this.requestParams,
          'page[size]': 5,
          'fields[stocks]': 'product,product_code,product_name,product_category_code,product_category_name,stock_real',
          'fields[products]': 'code',
          'filter[search]': this.filter.search,
          'filter[has_stock_real]': this.filter.has_stock_real,
          'include': 'product',
          ...params,
        },
      });

      if (!error) {
        this.stocks = res;
      }

      this.loading = false;
    },
    onClickStock(stock) {
      if (stock.attributes.stock_real > 0) {
        this.$emit('click-stock', stock)
      }
    },
    onOpened() {
      this.filter.search = null;
      this.filter.has_stock_real = true;

      this.loadStocks();
    },
    onPagechanged(page) {
      this.loadStocks({
        'page[number]': page,
      });
    },
  },
};
</script>
