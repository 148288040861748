export const OfficeInterface = {
  type: 'offices',
  id: '',
  attributes: {
    office_type: '',
    code: '',
    temp_code: null,
    prefix_order: null,
    balance: 0,
    order_balance: 0,
    stockist_debt_balance: 0,
    deposit_balance: 0,
    debt_balance: 0,
    restock_balance: 0,
    overpayment_balance: 0,
    name: '',
    ktp_number: null,
    birthdate: null,
    spouse_name: null,
    spouse_birthdate: null,
    devisor_name: null,
    devisor_birthdate: null,
    bank_account_number: null,
    bank_account_name: null,
    bank_name: null,
    bank_branch_name: null,
    npwp_number: null,
    npwp_name: null,
    npwp_address: null,
    npwp_address2: null,
    pasif: '',
    urut: '',
    address: '',
    address2: '',
    postal_code: null,
    kta: '',
    phone: '',
    email: '',
    pulau: '0',
    is_active: true,
    is_order_disabled: 0,
    is_suspended: false,
    is_kta_need_delivery: false,
    sex: null,
    is_promoted: false,
    promoted_office_id: null,
    marital_status: null,
    no_account_gl: 0,
    cost_center: 0,
    bonus_month: 0,
    address_details: [
      {
        office: '',
        province: '',
        city: '',
        district: null,
        village: null,
        detail: null,
        createdAt: '',
        updatedAt: '',
      },
    ],
  },
  relationships: {
    education: {
      data: {
        id: '',
        type: 'educations',
      },
    },
    profession: {
      data: {
        id: '',
        type: 'professions',
      },
    },
  },
};

export const StockInterface = {
  type: 'stocks',
  id: '',
  attributes: {
    bonus_value: 0,
    bonus_point: 0,
    is_point: 0,
    product_name: '',
    product_code: '',
    warehouse_code: '',
    license_status: false,
    stock_real: 0,
    stock_qty: 0,
    stock_ordered: 0,
    stock_in_delivery: 0,
    stock_complains: 0,
    stock_weight: 0,
    total_weight: 0,
    product_limit_id: '0',
    is_orderable: true,
    origin_office_category: ['pusat'],
    destination_office_category: [
      'stockist_center',
      'kantor_pelayanan',
      'stockist',
      'member',
    ],
    is_pre_orderable: false,
    orderable_stock: 0,
    pre_orderable_stock: 0,
    product_price: 0,
    expired_count: 0,
    damaged_count: 0,
    suitable_for_sale_count: 0,
    unsuitable_for_sale_count: 0,
    expired_at: null,
    createdAt: '',
    updatedAt: '',
  },
  relationships: {
    warehouse: {},
    product: {},
  },
};

export const ReturDetailInterface = {
  type: 'retur-details',
  id: '',
  attributes: {
    product_id: '',
    product_code: '',
    product_name: '',
    product_qty: 0,
    product_received: 0,
    product_packed: 0,
    product_not_packed: 0,
    product_in_delivery: 0,
    product_not_received: 0,
    is_completed: 0,
    packet_completed: null,
    product_price: 0,
    total_price: 0,
    product_weight: '0.00',
    total_weight: '0.00',
    stockist_code: null,
    stockist_name: null,
    stockist_address: null,
    stockist_area: null,
    warehouse_code: null,
    current_stock: 0,
    point_value: '0.00',
    bonus_value: '0.00',
    origin_office_category_id: 0,
    destination_office_category_id: 0,
    origin_code: '004',
    destination_code: '',
    stockist_type: 'STOCKIST',
    createdAt: '',
    updatedAt: '',
  },
};

export const BarangInterface = {
  id_barang: null,
  kode_barang: null,
  nama_barang: null,
  stock: null,
  status: true,
  is_point: null,
  jumlah: null,
  harga: null,
  berat: null,
  total_harga: null,
  total_berat: null,
  bonus_value: null,
  bonus_point: null,
};

export const FakturInterface = {
  id: null,
  no_invoice: null,
  tanggal: null,
  id_member: null,
  id_gudang_stockist: null,
  kode_member: null,
  nama_pemilik: null,
  kode_gudang: null,
  wilayah_harga: null,
  address: null,
  city: null,
  phone: null,
};

export const PenjualanInterface = {
  faktur: FakturInterface,
  barang: [BarangInterface],
};
