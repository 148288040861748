<template>
  <div class="mx-auto flex flex-row bg-white">
    <section slot="pdf-content" class="w-4/5">
      <div
        class="html2pdf__page-break flex w-full flex-col bg-white pl-6 pr-12"
      >
        <!-- Header -->
        <h1 class="text-md font-extrabold">PT. NATURAL NUSANTARA</h1>
        <div class="flex w-full flex-col justify-between gap-y-6 xl:flex-row">
          <div class="flex w-full flex-col gap-y-1">
            <address class="text-xs">
              Jl. Ring Road Barat No. 72 Salakan Trihanggo Gamping Sleman
              Jogjakarta 55291
            </address>
            <p class="text-xs">Telp:0274-6499191</p>
          </div>
          <div class="flex flex-col md:flex-shrink">
            <div class="flex flex-row items-center gap-x-1 text-xs">
              <p class="w-24 font-bold text-black">No.Invoice</p>
              <p>:</p>
              <p class="flex-shrink-0 font-normal text-gray-500">
                {{ order.data.attributes.origin_code }}
              </p>
            </div>
            <div class="flex flex-row items-center gap-x-1 text-xs">
              <p class="w-24 font-bold text-black">Tgl Invoice</p>
              <p>:</p>
              <p class="flex-shrink-0 font-normal text-gray-500">
                {{ dayjs(order.data.attributes.updatedAt).format('ll LT') }}
              </p>
            </div>
            <div class="flex flex-row items-center gap-x-1 text-xs">
              <p class="w-24 font-bold text-black">Waktu</p>
              <p>:</p>
              <p class="flex-shrink-0 font-normal text-gray-500">
                {{ dayjs().format('DD/MM/YYYY') }}
              </p>
            </div>
            <div class="flex flex-row items-center gap-x-1 text-xs">
              <p class="w-24 font-bold text-black">Wilayah</p>
              <p>:</p>
              <p class="flex-shrink-0 font-normal text-gray-500">
                {{ order.data.attributes.area_name }} ({{
                  order.data.attributes.area_code
                }})
              </p>
            </div>
          </div>
        </div>

        <!-- Bill to -->
        <div class="mt-6 flex w-full justify-between gap-x-6">
          <div class="flex w-full flex-col">
            <h1 class="text-sm font-bold">Kepada:</h1>
            <p class="text-xs italic">
              Bpk/Ibu
              {{
                getRelationships(
                  order,
                  order.data.relationships['destination-office'].data.id
                )?.attributes.name
              }}
              {{ order.data.attributes.destination_warehouse_code }},
              <span>
                <address>
                  {{
                    getRelationships(
                      order,
                      order.data.relationships['destination-office'].data.id
                    )?.attributes.address
                  }},
                </address>
              </span>
              {{
                getRelationships(
                  order,
                  order.data.relationships['destination-office'].data.id
                )?.attributes.phone
              }}
            </p>
          </div>
        </div>

        <!-- Barang Point -->
        <div
          v-if="barangPoint.length > 0"
          class="mt-6 flex w-full justify-between gap-x-6"
        >
          <div class="flex w-full flex-col space-y-2 divide-y divide-gray-200">
            <h1 class="text-sm font-medium uppercase">Barang Berpoint</h1>
            <ul role="list" class="space-y-4">
              <li
                class="my-4"
                v-for="(barang, index) in barangPoint"
                :key="index"
              >
                <div class="flex w-full flex-row items-center justify-between">
                  <div class="flex w-full flex-col">
                    <div
                      class="flex w-full flex-row justify-between space-x-2 text-xs"
                    >
                      <p class="font-medium">
                        {{ barang.attributes.product_code }}
                      </p>
                      <p class="text-xs text-gray-500">
                        {{ barang.attributes.product_name.slice(0, 15) + '..' }}
                      </p>
                    </div>
                    <div class="flex w-full">
                      <div
                        class="flex w-full items-start justify-between text-xs text-gray-500"
                      >
                        <p>
                          {{ barang.attributes.product_qty | toCurrency }} x @{{
                            barang.attributes.product_price | toCurrency
                          }}
                        </p>

                        <p class="font-bold">
                          {{ barang.attributes.total_price | toCurrency }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <div class="flex justify-end">
              <div class="flex flex-row gap-x-2 py-2">
                <div class="sm:col-span-1">
                  <label
                    for="invoice"
                    class="font-base block text-xs text-gray-700"
                  >
                    Sub Total
                  </label>
                </div>
                <div class="sm:col-span-1">
                  <label
                    for="invoice"
                    class="block text-xs font-bold text-gray-900"
                  >
                    {{ getGrandTotalPrice(barangPoint) | toCurrency }}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Barang Non Point -->
        <div
          v-if="barangNonPoint.length > 0"
          class="mt-6 flex w-full justify-between gap-x-6"
        >
          <div class="flex w-full flex-col space-y-2 divide-y divide-gray-200">
            <h1 class="text-sm font-medium uppercase">Barang Non Point</h1>
            <ul role="list" class="space-y-4">
              <li
                class="my-4"
                v-for="(barang, index) in barangNonPoint"
                :key="index"
              >
                <div class="flex w-full flex-row items-center justify-between">
                  <div class="flex w-full flex-col">
                    <div
                      class="flex w-full flex-row justify-between space-x-2 text-xs"
                    >
                      <p class="font-medium">
                        {{ barang.attributes.product_code }}
                      </p>
                      <p class="text-xs text-gray-500">
                        {{ barang.attributes.product_name.slice(0, 15) + '..' }}
                      </p>
                    </div>
                    <div class="flex w-full">
                      <div
                        class="flex w-full items-start justify-between text-xs text-gray-500"
                      >
                        <p>
                          {{ barang.attributes.product_qty }} x @{{
                            barang.attributes.product_price | toCurrency
                          }}
                        </p>

                        <p class="font-bold">
                          {{ barang.attributes.total_price | toCurrency }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <div class="flex justify-end">
              <div class="flex flex-row gap-x-2 py-2">
                <div class="sm:col-span-1">
                  <label
                    for="invoice"
                    class="font-base block text-xs text-gray-700"
                  >
                    Sub Total
                  </label>
                </div>
                <div class="sm:col-span-1">
                  <label
                    for="invoice"
                    class="block text-xs font-bold text-gray-900"
                  >
                    {{ getGrandTotalPrice(barangNonPoint) | toCurrency }}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Footer -->
        <div class="flex w-full flex-col justify-between pt-2">
          <div class="flex justify-end py-2">
            <div class="flex flex-row gap-x-2">
              <div class="sm:col-span-1">
                <label
                  for="invoice"
                  class="font-base block text-xs text-gray-700"
                >
                  Total BV:
                </label>
              </div>
              <div class="sm:col-span-1">
                <label
                  for="invoice"
                  class="block text-xs font-bold text-gray-900"
                >
                  {{ getGrandTotalBV(order_details) | toCurrency }}
                </label>
              </div>
            </div>
          </div>

          <footer class="flex w-full flex-col items-end justify-end">
            <div class="w-full border-t-2 border-dashed border-gray-700">
              <!-- total pembelian -->
              <div class="flex justify-end pt-2">
                <div class="flex flex-row gap-x-2">
                  <div class="sm:col-span-1">
                    <label
                      for="invoice"
                      class="font-base block text-xs text-gray-700"
                    >
                      Total Pembelian
                    </label>
                  </div>
                  <div class="sm:col-span-1">
                    <label
                      for="invoice"
                      class="block text-xs font-bold text-gray-900"
                    >
                      {{
                        (getGrandTotalPrice(order_details) +
                          getGrandTotalPrice(order_details) * 0.11)
                          | toCurrency
                      }}
                    </label>
                  </div>
                </div>
              </div>
              <!-- dasar pengenaan pajak -->
              <div class="flex justify-end">
                <div class="flex flex-row gap-x-2">
                  <div class="sm:col-span-1">
                    <label
                      for="invoice"
                      class="font-base block text-xs text-gray-700"
                    >
                      Dasar Pengenaan Pajak
                    </label>
                  </div>
                  <div class="sm:col-span-1">
                    <label
                      for="invoice"
                      class="block text-xs font-bold text-gray-900"
                    >
                      {{ getGrandTotalPrice(order_details) | toCurrency }}
                    </label>
                  </div>
                </div>
              </div>
              <!-- ppn -->
              <div class="flex justify-end">
                <div class="flex flex-row gap-x-2">
                  <div class="sm:col-span-1">
                    <label
                      for="invoice"
                      class="font-base block text-xs text-gray-700"
                    >
                      PPN 11%
                    </label>
                  </div>
                  <div class="sm:col-span-1">
                    <label
                      for="invoice"
                      class="block text-xs font-bold text-gray-900"
                    >
                      {{
                        (getGrandTotalPrice(order_details) * 0.11) | toCurrency
                      }}
                    </label>
                  </div>
                </div>
              </div>

              <div class="flex flex-col pt-4">
                <div class="flex flex-row justify-between gap-x-2">
                  <div class="sm:col-span-1">
                    <label
                      for="invoice"
                      class="font-base block text-xs text-gray-700"
                    >
                      Catatan:
                    </label>
                  </div>
                  <div class="sm:col-span-1">
                    <label
                      for="invoice"
                      class="block text-xs font-bold text-gray-900"
                    >
                      {{ order.data.attributes.notes ?? '-' }}
                    </label>
                  </div>
                </div>
                <div class="flex flex-row justify-between gap-x-2">
                  <div class="sm:col-span-1">
                    <label
                      for="invoice"
                      class="font-base block text-xs text-gray-700"
                    >
                      Kode Gudang:
                    </label>
                  </div>
                  <div class="sm:col-span-1">
                    <label
                      for="invoice"
                      class="block text-xs font-bold text-gray-900"
                    >
                      {{
                        order.data.attributes.destination_warehouse_code ?? '-'
                      }}
                    </label>
                  </div>
                </div>
              </div>

              <div class="my-6 flex w-full justify-between">
                <div
                  class="mt-6 border-t-2 border-dashed border-gray-700 text-gray-700 sm:col-span-1"
                >
                  <label
                    for="invoice"
                    class="block text-xs font-bold text-gray-900"
                  >
                    Bag. Penjualan
                  </label>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import dayjs from 'dayjs';
import { mapGetters } from 'vuex';

export default {
  components: {},

  props: {
    order: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      dataPenjualan: {},
    };
  },
  computed: {
    ...mapGetters({
      getLoading: 'orders/getLoading',
    }),
    order_details() {
      return this.order.data.relationships?.['order-details']?.data?.map((it) =>
        this.getRelationships(this.order, it.id)
      );
    },
    barangPoint() {
      return this.order_details?.filter((it) => it.attributes.is_point) || [];
    },
    barangNonPoint() {
      return this.order_details?.filter((it) => !it.attributes.is_point) || [];
    },
  },
  methods: {
    dayjs,
    getGrandTotalPrice(value) {
      return value?.reduce(function (total, current) {
        return total + (current.attributes.total_price ?? 0);
      }, 0);
    },
    getGrandTotalWeight(value) {
      return value?.reduce(function (total, current) {
        return total + parseFloat(current.total_weight);
      }, 0);
    },
    getGrandTotalBV(value) {
      return value?.reduce(function (total, current) {
        return total + ((current.bonus_value || 0) * current.product_qty || 0);
      }, 0);
    },
    getRelationships(resource, id) {
      let data = resource.included?.find(function (el) {
        return el.id === id;
      });
      return data;
    },
  },
};
</script>
