<template>
  <div class="mx-auto px-4 pb-12 sm:px-4 lg:px-8">
    <BaseCard title="Pengiriman Barang ke Mitra">
      <div class="flex flex-col">
        <div class="flex items-center justify-end space-x-2">
          <select
            v-model="is_shipped"
            @change="filterByCompleted"
            class="focus:outline-none block rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-green-500 focus:ring-green-500 sm:text-sm"
          >
            <option :value="null">Semua</option>
            <option :value="true">Terkirim</option>
            <option :value="false">Belum dikirim</option>
          </select>
          <base-search
            v-model="search"
            @input="debounceSearch"
            placeholder="Cari kode atau nama"
          />
        </div>
        <div class="flex my-2 sm:-mx-6 lg:-mx-8">
          <div
            class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"
          >
            <Datatable
              :total="getStockMovements.meta.page.total"
              :perPage="getStockMovements.meta.page.perPage"
              :currentPage="getStockMovements.meta.page.currentPage"
              @pagechanged="onPageChangeOrder"
              :meta="getStockMovements.meta"
              :is-empty="!getStockMovements.data?.length"
              cursor
            >
              <template v-slot:thead>
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Nomor Pengemasan
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Mitra Usaha
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Tanggal Pengemasan
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Total Koli
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Total Barang
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      STATUS
                    </th>
                  </tr>
                </thead>
              </template>
              <template v-slot:tbody>
                <div v-if="isLoading" class="flex items-center text-center">
                  <loading></loading>
                </div>
                <tbody v-if="getStockMovements.data?.length > 0">
                  <tr
                    class="cursor-pointer bg-white hover:bg-green-100"
                    v-for="(data, dataIdx) in getStockMovements.data"
                    :key="data.id"
                    :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                    @click="redirectToDetail(data)"
                  >
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                    >
                      <div class="text-sm font-bold text-gray-900">
                        {{ data.attributes.code }} /
                        <span class="text-xs text-gray-400">{{
                          getRelationships(
                            getStockMovements,
                            data.relationships['order'].data?.id
                          )?.attributes?.origin_code
                        }}</span>
                      </div>
                      <div class="text-xs text-gray-500">
                        {{ dayjs(data.attributes.updatedAt).format('ll LT') }}
                      </div>
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      {{
                        getRelationships(
                          getStockMovements,
                          data.relationships['destination-office'].data.id
                        ).attributes.code
                      }}
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      <div class="text-sm text-gray-500">
                        {{ dayjs(data.attributes.updatedAt).format('ll') }}
                      </div>
                      <div class="text-xs font-light text-gray-300">
                        {{ dayjs(data.attributes.updatedAt).format('LT') }}
                      </div>
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                    >
                      {{
                        data.relationships['stock-movement'].data ? getRelationships(
                          getStockMovements,
                          data.relationships['stock-movement'].data.id
                        ).attributes.packets?.length : '-'
                      }}
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                    >
                      {{
                        data.relationships['stock-movement'].data ? Object.keys(
                          getRelationships(
                            getStockMovements,
                            data.relationships['stock-movement'].data.id
                          ).attributes.product_summaries
                        ).length : '-'
                      }}
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      <base-badge
                        :color="data.attributes.is_shipped ? 'green' : 'red'"
                      >
                        {{
                          data.attributes.is_shipped
                            ? 'Terkirim'
                            : 'Belum Dikirim'
                        }}
                      </base-badge>
                    </td>
                  </tr>
                </tbody>
              </template>
            </Datatable>
          </div>
        </div>
      </div>
    </BaseCard>
  </div>
</template>

<script>
import { debounce } from 'debounce';
import { mapGetters, mapActions } from 'vuex';
import Datatable from '@/components/base/Datatable';
import BaseSearch from '@/components/base/Search.vue';
import dayjs from 'dayjs';

export default {
  name: 'Home',
  components: {
    Datatable,
    BaseSearch,
  },

  data() {
    return {
      search: null,
      is_shipped: false,
    };
  },

  computed: {
    ...mapGetters({
      getStockMovements: 'stock_movements/getStockMovements',
      isLoading: 'stock_movements/getLoading',
    }),
  },
  methods: {
    dayjs,
    ...mapActions({
      fetchStockMovements: 'stock_movements/fetchStockMovements',
      sendStockMovement: 'stock_movements/sendStockMovement',
    }),
    loadStockMovements({ ...params } = {}) {
      this.fetchStockMovements({
        'filter[is_valid_for_shipment]': true,
        'filter[is_shipped]': this.is_shipped,
        'filter[search]': this.search,
        'filter[origin_office_category_id]': 4,
        'filter[destination_office_category_id]': 5,
        include: 'order,stock-movement,destination-office,origin-office',
        ...params,
      });
    },
    debounceSearch: debounce(function () {
      this.loadStockMovements();
    }, 300),
    onPageChangeOrder(page) {
      this.loadStockMovements(page);
    },
    filterByCompleted() {
      this.loadStockMovements();
    },
    redirectToDetail(data) {
      this.$router.push(
        `/gudang/pengiriman/barang-ke-mitra/${data.attributes.stock_movement_id}`
      );
    },
    getRelationships(resource, id) {
      let data = resource.included.filter(function (el) {
        return el.id === id;
      });
      return data[0];
    },
  },
  created() {
    this.loadStockMovements();
  },
};
</script>
