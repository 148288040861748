<template>
  <div class="mx-auto px-4 pb-12 sm:px-4 lg:px-8">
    <div v-if="isLoading" class="justify-center text-center">
      <loading></loading>
    </div>
    <div class="space-y-4" v-else>
      <DetailPengiriman view-as="member" />

      <BaseCard
        title="Informasi Kemasan"
        description="Daftar kemasan yang akan dikirim ke Mitra"
      >
        <div class="mb-8 grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-12">
          <div class="sm:col-span-12">
            <div class="field-inset-default w-full bg-gray-100">
              <label
                for="kode_po"
                class="block text-xs font-bold text-gray-700"
              >
                Nama Kemasan
              </label>
              <p
                class="block w-full border-0 p-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
              >
                {{ getStockMovement.data.attributes.code }}
              </p>
            </div>
          </div>
        </div>
        <Datatable :paginated="false" :footer="false" class="mt-2">
          <template v-slot:thead>
            <thead class="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  Kode Koli
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  Jumlah Produk
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  Aksi
                </th>
              </tr>
            </thead>
          </template>
          <template v-slot:tbody>
            <tbody>
              <tr
                class="bg-white"
                v-for="(koli, index) in getStockMovement.data.attributes
                  .packets"
                :key="index"
              >
                <td
                  @click="detailModal(koli)"
                  class="cursor-pointer whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                >
                  {{ koli.name }}
                </td>
                <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                  {{ koli.products.length }}
                </td>
                <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                  <div class="flex m-auto space-x-2">
                    <button type="button">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M5 4v3H4a2 2 0 00-2 2v3a2 2 0 002 2h1v2a2 2 0 002 2h6a2 2 0 002-2v-2h1a2 2 0 002-2V9a2 2 0 00-2-2h-1V4a2 2 0 00-2-2H7a2 2 0 00-2 2zm8 0H7v3h6V4zm0 8H7v4h6v-4z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </Datatable>
      </BaseCard>

      <div
        v-if="getStockMovement.data.attributes.is_shipped"
        class="mb-5 rounded-lg bg-white p-4 sm:p-6 md:p-8"
      >
        <div class="flex justify-between pb-5">
          <div>
            <h3 class="text-lg font-bold leading-6 text-gray-900">
              Informasi Pengeluaran
            </h3>
            <p class="text-sm text-gray-600">
              Detail Pengeluaran yang akan dikirim ke Mitra
            </p>
          </div>
        </div>
        <div
          v-if="
            getRelationships(
              getStockMovement,
              getStockMovement.data.relationships['order'].data.id
            ).attributes.order_shipment === 'delivery'
          "
        >
          <div class="mb-8 grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-12">
            <div class="sm:col-span-12">
              <div class="field-inset-default w-full bg-gray-100">
                <label
                  for="kode_po"
                  class="block text-xs font-bold text-gray-700"
                >
                  Nomor Resi
                </label>
                <p
                  class="block w-full border-0 p-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                >
                  {{ dataPengiriman.no_resi }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          v-else-if="
            getRelationships(
              getStockMovement,
              getStockMovement.data.relationships['order'].data.id
            ).attributes.order_shipment === 'pickup'
          "
        >
          <div class="mb-8 grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-12">
            <div class="sm:col-span-12">
              <div class="field-inset-default w-full bg-gray-100">
                <label
                  for="kode_po"
                  class="block text-xs font-bold text-gray-700"
                >
                  Diambil oleh
                </label>
                <p
                  class="block w-full border-0 p-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                >
                  {{ dataPengiriman.taken_by }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="pt-5">
        <div class="flex justify-between">
          <div>
            <div class="flex space-x-2">
              <base-button
                color="white"
                v-if="!getStockMovement.data.attributes.is_shipped"
                @click="cancelStockMovementConfirmation = true"
              >
                Batalkan Faktur Kemasan
              </base-button>
            </div>
          </div>
          <div>
            <base-button
              v-if="!getStockMovement.data.attributes.is_shipped"
              @click="validateConfirmation = true"
            >
              Kirim!
            </base-button>
            <base-button color="white" :to="indexPath"> Kembali </base-button>
          </div>
        </div>
      </div>

      <!-- Detail Koli -->
      <modal-detail-koli
        :visible="openDetail"
        :packet="detailKoli"
        @close="openDetail = !openDetail"
      />

      <!-- MODAL VALIDASI KEMASAN-->
      <modal-validasi-pengiriman
        v-model="validateConfirmation"
        :data-pengiriman="dataPengiriman"
        @close="() => (validateConfirmation = !validateConfirmation)"
      />

      <!-- MODAL BATAL FAKTUR PENGEMASAN -->
      <transition
        name="customFade"
        enter-active-class="fadeIn"
        leave-active-class="fadeOut"
      >
        <div
          v-if="cancelStockMovementConfirmation"
          style="animation-duration: 0.3s"
          class="fixed inset-0 z-10"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div
            class="flex mx-4 min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
          >
            <div
              class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>
            <span
              class="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
              >&#8203;</span
            >
            <div
              class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle"
            >
              <form @submit.prevent="cancelStockMovement">
                <div>
                  <div
                    class="flex mx-auto h-12 w-12 items-center justify-center rounded-full bg-red-100"
                  >
                    <!-- Heroicon name: outline/check -->
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-6 w-6 text-red-600"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </div>
                  <div class="mt-3 text-center sm:mt-5">
                    <h3
                      class="text-lg font-medium leading-6 text-gray-900"
                      id="modal-title"
                    >
                      Batalkan Faktur Pengemasan
                    </h3>
                    <div class="mt-2">
                      <p class="text-sm text-gray-500">
                        Sudah ada faktur pengemasan. Anda yakin ingin
                        membatalkan?
                      </p>
                    </div>
                  </div>
                </div>
                <div class="mt-5 sm:mt-6 sm:flex sm:flex-row sm:gap-x-3">
                  <base-button type="submit" fullwidth> Ya </base-button>
                  <base-button
                    @click="cancelStockMovementConfirmation = false"
                    type="button"
                    fullwidth
                    color="white"
                  >
                    Tutup
                  </base-button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import dayjs from 'dayjs';
import { StorageService } from '@/services/storage.service';
import Loading from '@/components/Loading.vue';
import Datatable from '@/components/base/Datatable';
import ModalDetailKoli from '@/components/pengiriman/modal-detail-koli.vue';
import ModalValidasiPengiriman from '@/components/pengiriman/modal-validasi-pengiriman.vue';
import DetailPengiriman from '@/components/stock-movement/stock-movement-detail.vue';

export default {
  name: 'TambahPengiriman',
  components: {
    Datatable,
    Loading,
    ModalDetailKoli,
    ModalValidasiPengiriman,
    DetailPengiriman,
  },
  data() {
    return {
      indexPath: '/gudang/pengiriman/barang-ke-mitra',
      openDetail: false,
      validateConfirmation: false,
      cancelStockMovementConfirmation: false,
      dataPengiriman: {
        no_resi: null,
        taken_by: null,
        expedition: null,
      },
      detailKoli: {},
    };
  },
  created() {
    if (this.$route.params.id) {
      this.fetchStockMovement({
        id: this.$route.params.id,
      }).then((response) => {
        const stockMovement = response.data.data;
        if (stockMovement.attributes.is_shipped) {
          this.dataPengiriman.no_resi =
            stockMovement.attributes.airwaybill_number;
          this.dataPengiriman.taken_by = stockMovement.attributes.taken_by;
        }
      });
    }
    this.fetchOfficeExpeditionsByOffice({
      office_id: StorageService.getUser().office_id,
      params: {
        include: 'expedition-detail,expedition',
      },
    });
    const handleEscape = () => {
      this.success = false;
      this.failed = false;
    };
    this.setEscape(handleEscape);
  },
  computed: {
    ...mapGetters({
      isLoading: 'stock_movements/getLoading',
      getStockMovement: 'stock_movements/getStockMovement',
      getOfficeExpeditions: 'office_expeditions/getOfficeExpeditions',
    }),
  },
  methods: {
    dayjs,
    ...mapActions({
      fetchStockMovement: 'stock_movements/fetchStockMovement',
      deleteStockMovement: 'stock_movements/deleteStockMovement',
      fetchOfficeExpeditionsByOffice:
        'office_expeditions/fetchOfficeExpeditionsByOffice',
    }),
    getRelationships(resource, id) {
      let data = resource.included.filter(function (el) {
        return el.id === id;
      });
      return data[0];
    },
    detailModal(koli) {
      this.openDetail = !this.openDetail;
      this.detailKoli = koli;
    },
    cancelStockMovement() {
      this.deleteStockMovement({
        id: this.getStockMovement.data.id,
      }).then((response) => {
        if (response) {
          this.$router.push(this.indexPath);
        }
      });
    },
  },
};
</script>
