<template>
    <div class="grid grid-cols-1 sm:grid-cols-2 gap-6 lg:grid-cols-4">
        <base-widget label="Total Harga Stok Tersedia" :value="getTotalStockAvailable.total_price_stock_available" />
        <base-widget label="Total PV Tersedia" :value="getTotalStockAvailable.total_point_stock_available" />
        <base-widget label="Total BV Tersedia" :value="getTotalStockAvailable.total_bonus_stock_available" />
    </div>
</template>

<script>
import BaseWidget from '@/components/base/BaseWidget.vue';
import { mapActions, mapGetters } from 'vuex'

export default {
    components: { BaseWidget },
    computed: {
        ...mapGetters({
            getTotalStockAvailable: 'stocks/getTotalStockAvailable',
            me: 'auth/getUser'
        })
    },
    methods: {
        ...mapActions({
            fetchTotalStockAvailable: 'stocks/fetchTotalStockAvailable'
        })
    },
    created() {
        this.fetchTotalStockAvailable({
            params: {
                'filter[warehouse_uuid]': this.me.current_warehouse
            }
        })
    }
}
</script>