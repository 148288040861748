var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('base-modal',{attrs:{"showing":_vm.visible,"size":"max-w-5xl"},on:{"opened":_vm.onOpened,"close":function($event){return _vm.$emit('close')}}},[_c('div',{staticClass:"space-y-6"},[_c('div',{staticClass:"flex items-center justify-between"},[_c('div',{staticClass:"flex items-center space-x-4"},[_c('div',[_c('h3',{staticClass:"text-lg font-bold leading-6 text-gray-900"},[_vm._v(" Daftar Barang ")]),_c('p',{staticClass:"mt-1 max-w-2xl text-sm text-gray-500"},[_vm._v(" Daftar Barang Yang Telah Ditambahkan ")])])])]),_c('div',{staticClass:"space-y-4 border-t border-gray-200 pt-6"},[_c('div',{staticClass:"flex gap-x-2 justify-end"},[_c('base-select',{attrs:{"shadow":false,"expand":false,"options":[
              { id: 'all', value: null, label: 'Semua' },
              { id: 'on_tock', value: true, label: 'Ada Stock' }
            ]},on:{"change":_vm.loadStocks},model:{value:(_vm.filter.has_stock_real),callback:function ($$v) {_vm.$set(_vm.filter, "has_stock_real", $$v)},expression:"filter.has_stock_real"}}),_c('base-input',{attrs:{"placeholder":"Cari Barang","type":"search","shadow":false,"debounce":""},on:{"native-input":_vm.loadStocks},model:{value:(_vm.filter.search),callback:function ($$v) {_vm.$set(_vm.filter, "search", $$v)},expression:"filter.search"}})],1),_c('datatable',{attrs:{"columns":_vm.columns,"scroll-x":false,"total":_vm.stocks.meta.page.total,"perPage":_vm.stocks.meta.page.perPage,"currentPage":_vm.stocks.meta.page.currentPage},on:{"pagechanged":_vm.onPagechanged},scopedSlots:_vm._u([{key:"tbody",fn:function({ classes }){return _vm._l((_vm.stocks.data),function(stock){return _c('tr',{key:stock.id,class:[
                classes.tr,
                stock.attributes.stock_real > 0 ? 'cursor-pointer bg-white hover:bg-green-100' : 'bg-gray-50',
              ],on:{"click":function($event){return _vm.onClickStock(stock)}}},[_c('td',{class:[classes.td, 'font-bold text-gray-900']},[_vm._v(" "+_vm._s(stock.attributes.product_code)+" ")]),_c('td',{class:[classes.td]},[_vm._v(" "+_vm._s(stock.attributes.product_name)+" ")]),_c('td',{class:[classes.td]},[_vm._v(" "+_vm._s(stock.attributes.product_category_name)+" ")]),_c('td',{class:[classes.td]},[_vm._v(" "+_vm._s(stock.attributes.stock_real)+" ")])])})}}])})],1)])]),(_vm.loading)?_c('loading'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }