<template>
  <div class="mx-4 py-4 md:mx-6 md:py-6">
    <div class="mb-5 rounded-lg bg-white p-4 sm:p-6 md:p-8">
      <div class="pb-5">
        <h3 class="text-lg font-bold leading-6 text-gray-900">
          Faktur Pembayaran
        </h3>
      </div>
      <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-6">
        <div class="sm:col-span-6">
          <div
            class="field-inset-default relative w-full"
            :class="{ 'bg-gray-100': is_detail }"
          >
            <label for="name" class="block text-xs font-bold text-gray-700">
              No Invoice
            </label>
            <button
              type="button"
              class="flex absolute inset-y-0 top-4 right-4 cursor-pointer items-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 text-gray-500 hover:text-gray-700"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M9 9a2 2 0 114 0 2 2 0 01-4 0z" />
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a4 4 0 00-3.446 6.032l-2.261 2.26a1 1 0 101.414 1.415l2.261-2.261A4 4 0 1011 5z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
            <input
              v-model="dataPembayaran.no_penjualan"
              :disabled="true"
              ref="focusNoPenjualan"
              type="text"
              name="no_penjualan"
              id="no_penjualan"
              class="input-inset-select"
              :class="{ 'bg-gray-100': is_detail }"
              placeholder="Masukkan No Penjualan yang akan dibayarkan"
            />
          </div>
        </div>
        <div class="sm:col-span-3">
          <div class="field-inset-default relative bg-gray-100">
            <label
              for="kode_stockist"
              class="block text-xs font-bold text-gray-700"
            >
              Mitra Usaha
            </label>
            <input
              v-model="dataPembayaran.kode_stockist"
              readonly
              type="text"
              name="kode_stockist"
              id="kode_stockist"
              class="input-inset-select bg-gray-100"
              placeholder="ex: SCR338"
            />
          </div>
        </div>
        <div class="sm:col-span-3">
          <div class="field-inset-default relative bg-gray-100">
            <label
              for="nama_pemilik"
              class="block text-xs font-bold text-gray-700"
            >
              Nama Pemilik
            </label>
            <input
              v-model="dataPembayaran.nama_pemilik"
              readonly
              type="text"
              name="nama_pemilik"
              id="nama_pemilik"
              class="input-inset-select bg-gray-100"
              placeholder="ex: AYATULLOH MUHAMMAD DEVA"
            />
          </div>
        </div>
        <div class="sm:col-span-3">
          <div class="field-inset-default relative bg-gray-100">
            <label
              for="kode_gudang"
              class="block text-xs font-bold text-gray-700"
            >
              Kode Gudang
            </label>
            <input
              v-model="dataPembayaran.kode_gudang"
              readonly
              type="text"
              name="kode_gudang"
              id="kode_gudang"
              class="input-inset-select bg-gray-100"
              placeholder="ex: GD123"
            />
          </div>
        </div>
        <div class="sm:col-span-3">
          <div class="field-inset-default relative bg-gray-100">
            <label
              for="wilayah_harga"
              class="block text-xs font-bold text-gray-700"
            >
              Wilayah Harga
            </label>
            <input
              v-model="dataPembayaran.wilayah_harga"
              readonly
              type="text"
              name="wilayah_harga"
              id="wilayah_harga"
              class="input-inset-select bg-gray-100"
              placeholder="ex: Wilayah 1"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="mb-5 rounded-lg bg-white p-4 sm:p-6 md:p-8">
      <div class="pb-5">
        <h3 class="text-lg font-bold leading-6 text-gray-900">
          Metode Pembayaran
        </h3>
      </div>
      <div
        class="flex w-full flex-row gap-x-4 gap-y-2 pb-4"
        v-for="(method, index) in dataPembayaran.payment_methods"
        :key="`metode-${index}`"
      >
        <div class="w-full">
          <div class="field-inset-default relative">
            <label
              for="nominal_pembayaran"
              class="block text-xs font-bold text-gray-700"
            >
              Metode Pembayaran
            </label>
            {{
              getRelationships(
                dataPembayaran.banks,
                method.relationships['bank'].data.id
              ).attributes.name
            }}
          </div>
        </div>
        <div class="relative w-full">
          <div class="field-inset-default">
            <label
              for="nama_pemilik"
              class="block text-xs font-bold text-gray-700"
            >
              Nominal Pembayaran
            </label>
            {{ method.attributes.payment_amount }}
          </div>
        </div>
      </div>
    </div>

    <div class="pt-5">
      <div class="flex justify-end">
        <base-button color="danger" @click="confirmation = !confirmation">
          Batalkan Pembayaran
        </base-button>
        <base-button
          color="white"
          :to="`/kasir/pembayaran/faktur-penjualan/${dataPembayaran.id_penjualan}/pembatalan`"
        >
          Kembali
        </base-button>
      </div>
    </div>

    <!-- MODAL CONFIRMATION-->
    <transition name="fade">
      <div
        v-if="confirmation"
        class="fixed inset-0 z-10 overflow-y-auto"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <modal
          @modal-action="successModal"
          link="/kasir/pembayaran/faktur-penjualan"
          :actions="false"
        >
          <template v-slot:svg>
            <div
              class="flex mx-auto h-12 w-12 items-center justify-center rounded-full bg-red-100"
            >
              <!-- Heroicon name: outline/check -->
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 text-red-600"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
          </template>
          <template v-slot:heading>
            <h3
              class="text-lg font-semibold leading-6 text-gray-900"
              id="modal-title"
            >
              Apakah anda yakin ingin menghapus faktur pembayaran?
            </h3>
          </template>
          <template v-slot:actions>
            <button
              @click="cancelPayment"
              class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-sm"
            >
              Ya
            </button>
            <button
              @click="confirmation = !confirmation"
              class="bg-white-600 focus:outline-none inline-flex w-full justify-center rounded-md border border-gray-300 px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-white hover:shadow-lg focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:text-sm"
            >
              Kembali
            </button>
          </template>
        </modal>
      </div>
    </transition>

    <!-- MODAL SUCCESS-->
    <transition name="fade">
      <div
        v-if="success"
        class="fixed inset-0 z-10 overflow-y-auto"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <modal
          @modal-action="successModal"
          :link="`/kasir/pembayaran/faktur-penjualan/${dataPembayaran.id_penjualan}/pembatalan`"
        >
          <template v-slot:svg>
            <div
              class="flex mx-auto h-12 w-12 items-center justify-center rounded-full bg-green-100"
            >
              <!-- Heroicon name: outline/check -->
              <svg
                class="h-6 w-6 text-green-600"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 13l4 4L19 7"
                />
              </svg>
            </div>
          </template>
          <template v-slot:heading>
            <h3
              class="text-lg font-bold leading-6 text-gray-900"
              id="modal-title"
            >
              Data berhasil disimpan
            </h3>
          </template>
        </modal>
      </div>
    </transition>

    <!-- MODAL ERROR -->
    <transition name="fade">
      <div
        v-if="getError"
        class="fixed inset-0 z-10 overflow-y-auto"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <modal
          @modal-action="failedModal"
          :link="`/kasir/pembayaran/faktur-penjualan/${dataPembayaran.id_penjualan}/pembatalan`"
        >
          <template v-slot:svg>
            <div
              class="flex mx-auto h-12 w-12 items-center justify-center rounded-full bg-red-100"
            >
              <!-- Heroicon name: outline/check -->
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 text-red-600"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
          </template>
          <template v-slot:heading>
            <h3
              class="text-lg font-bold leading-6 text-gray-900"
              id="modal-title"
            >
              {{ getError }}
            </h3>
          </template>
        </modal>
      </div>
    </transition>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import dayjs from 'dayjs';
import Modal from '@/components/Modal.vue';

export default {
  name: 'TambaTransaksiPembayaran',
  components: {
    Modal,
  },
  data() {
    return {
      is_detail: false,
      success: false,
      failed: false,
      confirmation: false,
      dataPembayaran: {
        id: '',
        id_penjualan: null,
        no_penjualan: null,
        kode_stockist: null,
        nama_pemilik: null,
        kode_gudang: null,
        wilayah_harga: null,
        payment_methods: [],
        banks: [],
      },
    };
  },
  created() {
    if (this.$route.params.id_pembayaran) {
      this.is_detail = true;
      this.dataPembayaran.id = this.$route.params.id_pembayaran;
      this.dataPembayaran.id_penjualan = this.$route.params.id;
      this.fetchPayment({
        id: this.dataPembayaran.id,
      }).then((response) => {
        this.dataPembayaran.no_penjualan = response.data.data.attributes.code;
        this.dataPembayaran.kode_stockist = this.getRelationships(
          response.data.included,
          response.data.data.relationships['order'].data.id
        ).attributes.destination_office_code;
        this.dataPembayaran.nama_pemilik = this.getRelationships(
          response.data.included,
          response.data.data.relationships['order'].data.id
        ).attributes.destination_office;
        this.dataPembayaran.kode_gudang = this.getRelationships(
          response.data.included,
          response.data.data.relationships['order'].data.id
        ).attributes.origin_warehouse_code;
        this.dataPembayaran.wilayah_harga = this.getRelationships(
          response.data.included,
          response.data.data.relationships['order'].data.id
        ).attributes.area_code;
        response.data.data.relationships['payment-methods'].data.map(
          (method) => {
            this.fetchPaymentMethod({
              id: method.id,
            }).then((response) => {
              this.dataPembayaran.payment_methods.push(response.data.data);
              this.dataPembayaran.banks = this.dataPembayaran.banks.concat(
                response.data.included
              );
            });
          }
        );
      });
    }
    const handleEscape = () => {
      this.success = false;
      this.failed = false;
    };
    this.setEscape(handleEscape);
  },
  computed: {
    ...mapGetters({
      getError: 'payments/getError',
    }),
  },
  methods: {
    dayjs,
    ...mapActions({
      fetchPayment: 'payments/fetchPayment',
      updatePayment: 'payments/updatePayment',
      fetchPaymentMethod: 'payment_methods/fetchPaymentMethod',
    }),
    successModal() {
      this.success = !this.success;
      this.clearData();
    },
    failedModal() {
      this.failed = !this.failed;
      this.clearData();
    },
    clearData() {
      this.dataPembayaran = {
        id: '',
        no_penjualan: null,
        kode_stockist: null,
        nama_pemilik: null,
        kode_gudang: null,
        wilayah_harga: null,
        saldo_awal: null,
        total_penjualan: null,
        invoices: [],
      };
    },
    getRelationships(resource, id) {
      let data = resource.filter(function (el) {
        return el.id === id;
      });
      return data[0];
    },
    cancelPayment() {
      const payload = {
        data: {
          type: 'payments',
          id: this.dataPembayaran.id,
          attributes: {
            deletedAt: new Date(),
          },
        },
      };

      this.updatePayment(payload).then((response) => {
        this.confirmation = !this.confirmation;
        if (response) {
          this.$router.push(
            `/kasir/pembayaran/faktur-penjualan/${this.dataPembayaran.id_penjualan}`
          );
        } else {
          this.failed = true;
        }
      });
    },
  },
};
</script>
