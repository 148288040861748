<template>
  <div class="mx-auto px-4 pb-12 sm:px-4 lg:px-8">
    <div v-if="isLoading" class="justify-center text-center">
      <loading></loading>
    </div>
    <div class="mb-5 rounded-lg bg-white p-4 sm:p-6 md:p-8">
      <div class="border-b border-gray-200 pb-5">
        <h3 class="text-lg font-bold leading-6 text-gray-900">
          Informasi Supplier
        </h3>
      </div>
      <div class="py-2">
        <div class="mt-6 grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-6">
          <div class="sm:col-span-3">
            <div
              class="field-inset-default relative w-full"
              :class="{ 'bg-gray-100': is_detail }"
            >
              <label
                for="kode_po"
                class="block text-xs font-bold text-gray-700"
              >
                Kode PO
              </label>
              <button
                type="button"
                :disabled="is_detail"
                @click="openModal"
                class="flex absolute inset-y-0 top-4 right-4 cursor-pointer items-center"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5 text-gray-500 hover:text-gray-700"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M9 9a2 2 0 114 0 2 2 0 01-4 0z" />
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a4 4 0 00-3.446 6.032l-2.261 2.26a1 1 0 101.414 1.415l2.261-2.261A4 4 0 1011 5z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
              <input
                v-model="dataBrgMasuk.no_po"
                :disabled="is_detail"
                @blur="searchPO"
                type="text"
                name="kode_po"
                id="kode_po"
                class="block w-full border-0 p-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                :class="{ 'bg-gray-100': is_detail }"
                placeholder="Masukkan Kode Supplier"
              />
            </div>
          </div>
          <div class="sm:col-span-3">
            <div class="field-inset-default w-full bg-gray-100">
              <label
                for="tanggal"
                class="block text-xs font-bold text-gray-700"
              >
                Tanggal
              </label>
              <input
                v-model="dataBrgMasuk.po_date"
                type="date"
                name="tanggal"
                id="tanggal"
                class="block w-full border-0 bg-gray-100 p-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                disabled
              />
            </div>
          </div>
          <div class="sm:col-span-3">
            <div class="field-inset-default relative bg-gray-100">
              <label
                for="kode_supplier"
                class="block text-xs font-bold text-gray-700"
              >
                Kode Supplier
              </label>
              <input
                v-model="dataBrgMasuk.kode_supplier"
                type="text"
                name="kode_supplier"
                id="kode_supplier"
                class="block w-full border-0 bg-gray-100 p-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                placeholder="Kode Supplier..."
                disabled
              />
            </div>
          </div>
          <div class="sm:col-span-3">
            <div class="field-inset-default relative bg-gray-100">
              <label
                for="nama_supplier"
                class="block text-xs font-bold text-gray-700"
              >
                Nama Supplier
              </label>
              <input
                v-model="dataBrgMasuk.nama_supplier"
                type="text"
                name="nama_supplier"
                id="nama_supplier"
                class="block w-full border-0 bg-gray-100 p-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                placeholder="Nama Supplier..."
                disabled
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mb-5 rounded-lg bg-white p-4 sm:p-6 md:p-8">
      <div class="border-b border-gray-200 pb-5">
        <h3 class="text-lg font-bold leading-6 text-gray-900">
          Informasi Barang Masuk
        </h3>
        <p class="mt-2 max-w-4xl text-sm text-gray-500">
          Masukkan detail barang masuk
        </p>
      </div>
      <!-- Replace with your content -->
      <div class="flex my-2 sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <Datatable :paginated="false" :footer="false">
            <template v-slot:thead>
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    KODE BARANG
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    NAMA BARANG
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    NO BATCH
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    TANGGAL KADALUWARSA
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    JUMLAH
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    HARGA
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    TOTAL HARGA
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    AKSI
                  </th>
                </tr>
              </thead>
            </template>
            <template v-slot:tbody>
              <tbody v-if="dataBrgMasuk.details.length">
                <tr
                  class="bg-white"
                  v-for="(barang, index) in dataBrgMasuk.details"
                  :key="index"
                >
                  <td
                    class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                  >
                    {{ barang.product_code }}
                  </td>
                  <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                    {{ barang.product_name }}
                  </td>
                  <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                    <input
                      v-model="dataBrgMasuk.details[index].no_batch"
                      type="text"
                      name="no_batch"
                      class="block border-0 p-0 placeholder-gray-400 focus:ring-0 sm:text-sm"
                      placeholder="....."
                    />
                  </td>
                  <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                    <input
                      v-model="dataBrgMasuk.details[index].expired_at"
                      type="date"
                      name="expired"
                      class="block border-0 p-0 placeholder-gray-400 focus:ring-0 sm:text-sm"
                      placeholder="....."
                    />
                  </td>
                  <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                    <input
                      v-model="dataBrgMasuk.details[index].product_not_received"
                      @input="
                        changeTotal(barang['product_not_received'], index)
                      "
                      type="text"
                      name="kode_details"
                      class="block border-0 p-0 placeholder-gray-200 focus:ring-0 sm:text-sm"
                      placeholder="Jumlah..."
                    />
                  </td>
                  <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                    {{ barang.product_price }}
                  </td>
                  <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                    {{ barang.total_price }}
                  </td>
                  <td
                    class="flex justify-center whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                  >
                    <svg
                      @click="removeProduct(index)"
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-4 w-4 cursor-pointer text-red-300 hover:text-red-600"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr class="bg-white">
                  <td
                    class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                    colspan="8"
                  >
                    Data tidak ditemukan
                  </td>
                </tr>
              </tbody>
            </template>
          </Datatable>
        </div>
      </div>
    </div>

    <div class="pt-5">
      <div class="flex justify-end">
        <button
          @click="clearData"
          type="button"
          class="bg-white-600 ml-3 rounded-md border border-gray-300 py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-white hover:shadow-lg"
        >
          Batal
        </button>
        <button
          @click="addIncomingGood"
          class="ml-3 inline-flex rounded-md border border-gray-300 bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-600 hover:shadow-lg"
        >
          Simpan
        </button>
        <router-link
          to="/gudang"
          class="ml-3 inline-flex rounded-md border border-gray-300 bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-600 hover:shadow-lg"
        >
          Selesai
        </router-link>
      </div>
    </div>

    <transition
      name="customFade"
      enter-active-class="fadeIn"
      leave-active-class="fadeOut"
    >
      <div
        v-if="openListPO"
        class="fixed inset-0 z-10 overflow-y-auto"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div
          class="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
        >
          <div
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            aria-hidden="true"
          ></div>
          <span
            class="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
            >&#8203;</span
          >
          <div
            class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-6xl sm:p-6 sm:align-middle"
          >
            <div class="flex justify-between px-4 py-5 sm:px-6">
              <div>
                <h3 class="text-lg font-bold leading-6 text-gray-900">
                  Daftar Purchase Order
                </h3>
                <p class="mt-1 max-w-2xl text-sm text-gray-500">
                  Data purcahse order yang telah dibuat
                </p>
              </div>
              <div>
                <img src="@/assets/images/logo.png" alt="" class="w-52" />
              </div>
            </div>
            <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
              <div class="flex justify-end">
                <base-search
                  v-model="search"
                  @input="debounceSearch"
                  placeholder="Cari kode atau nama"
                />
              </div>
              <div class="flex my-2 sm:-mx-6 lg:-mx-8">
                <div
                  class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"
                >
                  <Datatable
                    :total="getOrders.meta.page.total"
                    :perPage="getOrders.meta.page.perPage"
                    :currentPage="getOrders.meta.page.currentPage"
                    @pagechanged="onPageChangePO"
                  >
                    <template v-slot:thead>
                      <thead class="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            Nomor PO
                          </th>
                          <th
                            scope="col"
                            class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            Tanggal PO
                          </th>
                          <th
                            scope="col"
                            class="px-6 py-3 pr-24 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            Total Produk
                          </th>
                          <th
                            scope="col"
                            class="px-6 py-3 pr-24 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            Total Berat
                          </th>
                          <th
                            scope="col"
                            class="px-6 py-3 pr-24 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            Total Harga
                          </th>
                          <th
                            scope="col"
                            class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            STATUS
                          </th>
                        </tr>
                      </thead>
                    </template>
                    <template v-slot:tbody>
                      <tbody>
                        <tr
                          class="cursor-pointer bg-white hover:bg-green-100"
                          v-for="(data, dataIdx) in getOrders.data"
                          :key="data.id"
                          :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                          @click.prevent="changePO(data)"
                        >
                          <td
                            class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                          >
                            {{ data.attributes.code }}
                          </td>
                          <td
                            class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                          >
                            {{
                              dayjs(data.attributes.updatedAt).format('ll LT')
                            }}
                          </td>
                          <td
                            class="whitespace-nowrap px-6 py-4 pr-24 text-right text-sm text-gray-500"
                          >
                            {{ data.attributes.item_count }}
                          </td>
                          <td
                            class="whitespace-nowrap px-6 py-4 pr-24 text-right text-sm text-gray-500"
                          >
                            {{ data.attributes.grand_total_weight }}
                          </td>
                          <td
                            class="whitespace-nowrap px-6 py-4 pr-24 text-right text-sm text-gray-500"
                          >
                            {{ data.attributes.final_price }}
                          </td>
                          <td
                            class="whitespace-nowrap px-6 py-4 text-center text-sm text-gray-500"
                          >
                            <span
                              v-if="data.attributes.is_completed"
                              class="inline-flex items-center rounded-full bg-green-100 px-3 py-1 text-sm font-medium text-green-800"
                            >
                              Selesai
                            </span>
                            <span
                              v-else
                              class="inline-flex items-center rounded-full bg-red-100 px-3 py-1 text-sm font-medium text-red-800"
                            >
                              Belum Selesai
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </Datatable>
                </div>
              </div>
            </div>
            <div class="flex justify-end">
              <button
                @click="openListPO = !openListPO"
                type="button"
                class="focus:outline-none inline-flex justify-end rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-sm"
              >
                Tutup
              </button>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <transition name="fade">
      <div
        v-if="success"
        class="fixed inset-0 z-10 overflow-y-auto"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <modal @modal-action="successModal" link="/gudang">
          <template v-slot:svg>
            <div
              class="flex mx-auto h-12 w-12 items-center justify-center rounded-full bg-green-100"
            >
              <!-- Heroicon name: outline/check -->
              <svg
                class="h-6 w-6 text-green-600"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 13l4 4L19 7"
                />
              </svg>
            </div>
          </template>
          <template v-slot:heading>
            <h3
              class="text-lg font-bold leading-6 text-gray-900"
              id="modal-title"
            >
              Data berhasil disimpan
            </h3>
          </template>
        </modal>
      </div>
    </transition>

    <transition name="fade">
      <div
        v-if="failed"
        class="fixed inset-0 z-10 overflow-y-auto"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <modal @modal-action="failedModal" link="/gudang">
          <template v-slot:svg>
            <div
              class="flex mx-auto h-12 w-12 items-center justify-center rounded-full bg-red-100"
            >
              <!-- Heroicon name: outline/check -->
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 text-red-600"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
          </template>
          <template v-slot:heading>
            <h3
              class="text-lg font-bold leading-6 text-gray-900"
              id="modal-title"
            >
              {{ getError[0].detail }}
            </h3>
          </template>
        </modal>
      </div>
    </transition>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import dayjs from 'dayjs';
import Datatable from '@/components/base/Datatable';
import Modal from '@/components/Modal.vue';
import Loading from '@/components/Loading.vue';
import { debounce } from 'debounce';
import { StorageService } from '@/services/storage.service';
import BaseSearch from '@/components/base/Search.vue';

export default {
  name: 'TambahPenjualan',
  components: {
    Datatable,
    Modal,
    Loading,
    BaseSearch,
  },
  data() {
    const destination_warehouse_id = StorageService.getUser().current_warehouse;

    return {
      destination_warehouse_id,
      origin_office_id: null,

      id_detail: false,
      is_detail: false,
      success: false,
      failed: false,
      openDetail: false,
      openListPO: false,
      search: null,
      dataBrgMasuk: {
        id_po: null,
        origin_warehouse_id: null,
        destination_warehouse_id: null,
        origin_office_id: null,
        destination_office_id: null,
        no_po: null,
        id_supplier: null,
        kode_supplier: null,
        nama_supplier: null,
        po_date: dayjs().format('YYYY-MM-DD'),
        details: [],
      },
    };
  },
  created() {
    this.fetchOffices({ office_category: 'Pusat' }).then((res) => {
      this.origin_office_id = res.data.data[0].id;
    });

    if (this.$route.params.id) {
      this.is_detail = true;
      this.dataBrgMasuk.id_po = this.$route.params.id;
      this.fetchOrder({
        id: this.dataBrgMasuk.id_po,
      }).then((response) => {
        this.dataBrgMasuk.no_po =
          response.data.data.attributes.destination_code;
        this.searchPO();
      });
    }
    const handleEscape = () => {
      this.success = false;
      this.failed = false;
    };
    this.setEscape(handleEscape);
  },
  computed: {
    ...mapGetters({
      isLoading: 'orders/getLoading',
      getOrders: 'orders/getOrders',
      getError: 'stock_movements/getError',
    }),
  },
  methods: {
    dayjs,
    ...mapActions({
      fetchOrders: 'orders/fetchOrders',
      fetchOrder: 'orders/fetchOrder',
      fetchWarehouseByOffice: 'warehouses/fetchWarehouseByOffice',
      createStockMovement: 'stock_movements/createStockMovement',
      fetchOffices: 'offices/fetchOffices',
    }),
    successModal() {
      this.success = !this.success;
      this.openDetail = true;
      this.clearData();
    },
    failedModal() {
      this.failed = !this.failed;
      this.clearData();
    },
    detailModal() {
      this.openDetail = !this.openDetail;
      this.clearData();
    },
    changeTotal(qty, index) {
      this.dataBrgMasuk.details[index].total_price =
        this.dataBrgMasuk.details[index].product_not_received *
        this.dataBrgMasuk.details[index].product_price;
    },
    openModal() {
      this.openListPO = !this.openListPO;
      this.fetchOrders({
        destination_warehouse_id: this.destination_warehouse_id,
        origin_office_id: this.origin_office_id,

        pageNumber: 1,
        pageSize: 5,
        is_completed: false,
      });
    },
    debounceSearch: debounce(function () {
      this.fetchOrders({
        destination_warehouse_id: this.destination_warehouse_id,
        origin_office_id: this.origin_office_id,

        pageNumber: 1,
        pageSize: 5,
        search: this.search,
        is_completed: false,
      });
    }, 500),
    onPageChangePO(page) {
      this.fetchOrders({
        destination_warehouse_id: this.destination_warehouse_id,
        origin_office_id: this.origin_office_id,

        pageNumber: page,
        pageSize: 5,
        search: this.search,
        is_completed: false,
      });
    },
    changePO(data) {
      this.openListPO = !this.openListPO;
      this.dataBrgMasuk.no_po = data.attributes.destination_code;
      this.searchPO();
      this.search = '';
    },
    searchPO() {
      if (this.dataBrgMasuk.no_po) {
        this.fetchOrders({
          destination_warehouse_id: this.destination_warehouse_id,
          origin_office_id: this.origin_office_id,

          search: this.dataBrgMasuk.no_po,
          is_completed: false,
        }).then(() => {
          if (this.getOrders.data.length > 0) {
            this.dataBrgMasuk.id_po = this.getOrders.data[0].id;
            this.dataBrgMasuk.no_po =
              this.getOrders.data[0].attributes.destination_code;
            this.dataBrgMasuk.origin_office_id =
              this.getOrders.data[0].relationships['origin-office'].data.id;
            this.dataBrgMasuk.destination_office_id =
              this.getOrders.data[0].relationships[
                'destination-office'
              ].data.id;
            this.dataBrgMasuk.id_supplier = this.getRelationships(
              this.getOrders.data[0].relationships['origin-office'].data.id
            ).id;
            this.dataBrgMasuk.kode_supplier = this.getRelationships(
              this.getOrders.data[0].relationships['origin-office'].data.id
            ).attributes.code;
            this.dataBrgMasuk.nama_supplier = this.getRelationships(
              this.getOrders.data[0].relationships['origin-office'].data.id
            ).attributes.name;
            this.dataBrgMasuk.details =
              this.getOrders.data[0].attributes.order_details.filter(function (
                item
              ) {
                return item.is_completed === 0;
              });
            this.dataBrgMasuk.origin_warehouse_id = this.getRelationships(
              this.getOrders.data[0].relationships['origin-warehouse'].data.id
            ).id;
            this.dataBrgMasuk.destination_warehouse_id = this.getRelationships(
              this.getOrders.data[0].relationships['destination-warehouse'].data
                .id
            ).id;
          } else {
            this.dataBrgMasuk.id_supplier = null;
            this.dataBrgMasuk.kode_supplier = null;
            this.dataBrgMasuk.nama_supplier = null;
          }
        });
      }
    },
    getRelationships(id) {
      let data = this.getOrders.included.filter(function (el) {
        return el.id === id;
      });
      return data[0];
    },
    removeProduct(index) {
      this.dataBrgMasuk.details.splice(index, 1);
    },
    getOrderDetails(params) {
      let dataDetail = [];
      params.map((param) => {
        let data = {
          product_id: param.product_id,
          received_product_qty: param.product_not_received,
          no_batch: param.no_batch,
          expired_at: param.expired_at,
        };
        dataDetail.push(data);
      });
      return {
        name: 'Koli 1',
        products: dataDetail,
      };
    },
    addIncomingGood() {
      const payload = {
        data: {
          type: 'stock-movements',
          attributes: {
            origin_warehouse_id: this.dataBrgMasuk.origin_warehouse_id,
            destination_warehouse_id:
              this.dataBrgMasuk.destination_warehouse_id,
            origin_office_id: this.dataBrgMasuk.origin_office_id,
            destination_office_id: this.dataBrgMasuk.destination_office_id,
            packets: [this.getOrderDetails(this.dataBrgMasuk.details)],
          },
          relationships: {
            order: {
              data: {
                type: 'orders',
                id: this.dataBrgMasuk.id_po,
              },
            },
          },
        },
      };
      this.createStockMovement(payload).then((response) => {
        if (response) {
          this.success = true;
        } else {
          this.failed = true;
        }
      });
    },
    clearData() {
      this.dataBrgMasuk = {
        id_po: null,
        no_po: null,
        id_supplier: null,
        kode_supplier: null,
        nama_supplier: null,
        po_date: dayjs().format('YYYY-MM-DD'),
        details: [],
      };
    },
  },
};
</script>
