<template>
  <div
    class="flex flex-row justify-between overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6"
  >
    <div class="flex flex-col items-start">
      <dt class="truncate text-sm font-medium text-gray-500">
        {{ data.title }}
      </dt>
      <dd class="mt-1 text-xl font-semibold tracking-tight text-gray-900">
        {{ data.value }}
      </dd>
    </div>
    <div class="p-2">
      <Icon :icon="data.icon" class="h-10 w-10 object-cover" />
    </div>
  </div>
</template>
<script>
import { Icon } from '@iconify/vue2';
export default {
  components: {
    Icon,
  },
  props: {
    data: {
      required: true,
      type: Object,
    },
  },
};
</script>
