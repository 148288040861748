<template>
  <div class="mx-auto px-4 pb-12 sm:px-4 lg:px-8">
    <div class="mb-5 rounded-lg py-4">
      <div class="flex flex-col">
        <div class="flex items-center justify-end space-x-2">
          <div class="relative mt-1 w-1/12 rounded-md shadow-sm">
            <select
              v-model="is_packed"
              @change="filterByPacked"
              class="inline-flex w-full appearance-none justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm"
            >
              <option :value="null">Semua</option>
              <option
                :value="true"
                class="block w-full px-4 py-2 text-sm text-gray-700 hover:bg-green-200 hover:text-green-600 focus:bg-green-400"
              >
                Selesai
              </option>
              <option
                :value="false"
                class="block w-full px-4 py-2 text-sm text-gray-700 hover:bg-green-200 hover:text-green-600 focus:bg-green-400"
              >
                Belum Selesai
              </option>
            </select>
          </div>
          <base-search
            v-model="search"
            @input="debounceSearch"
            placeholder="Cari kode atau nama"
          />
        </div>
        <div class="flex my-2 sm:-mx-6 lg:-mx-8">
          <div
            class="inline-block min-w-full cursor-not-allowed py-2 align-middle sm:px-6 lg:px-8"
          >
            <Datatable
              :total="getOrders.meta.page.total"
              :perPage="getOrders.meta.page.perPage"
              :currentPage="getOrders.meta.page.currentPage"
              @pagechanged="onPageChangeProducts"
            >
              <template v-slot:thead>
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                      v-for="item in [
                        'No. Penjualan',
                        'Susulan',
                        'Kode Gudang',
                        'Status Susulan',
                      ]"
                      :key="item"
                    >
                      {{ item }}
                    </th>
                  </tr>
                </thead>
              </template>
              <template v-slot:tbody>
                <div v-if="isLoading" class="flex items-center text-center">
                  <loading></loading>
                </div>
                <tbody v-if="getOrders.data?.length > 0">
                  <tr
                    class="bg-white"
                    v-for="(data, dataIdx) in getOrders.data"
                    :key="data.id"
                    :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                  >
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm font-bold text-gray-700"
                    >
                      <div class="text-sm font-bold text-gray-900">
                        {{ data.attributes.origin_code }} /
                        {{ data.attributes.destination_code }}
                      </div>
                      <div class="text-xs font-light text-gray-300">
                        {{ dayjs(data.attributes.updatedAt).format('ll LT') }}
                      </div>
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      {{ data.attributes.item_count }}
                      Produk
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      {{ data.attributes.origin_warehouse_code }}
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      <span
                        class="inline-flex items-center rounded-full bg-indigo-100 px-3 py-1 text-sm font-medium text-indigo-800"
                      >
                        {{ data.attributes.warehouse_status }}
                      </span>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr class="bg-white">
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                      colspan="8"
                    >
                      Data tidak ditemukan
                    </td>
                  </tr>
                </tbody>
              </template>
            </Datatable>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { debounce } from 'debounce';
import Datatable from '@/components/base/Datatable';
import { StorageService } from '@/services/storage.service';
import BaseSearch from '@/components/base/Search.vue';
import dayjs from 'dayjs';

export default {
  name: 'Users',
  components: {
    Datatable,
    BaseSearch,
  },
  data() {
    return {
      search: null,
      is_packed: null,
    };
  },
  created() {
    this.fetchOrders({
      ...this.ordersParams,
    });
  },
  computed: {
    ...mapGetters({
      getOrders: 'orders/getOrders',
      isLoading: 'orders/getLoading',
    }),

    ordersParams() {
      return {
        pageNumber: 1,
        pageSize: 5,
        destination_warehouse_id: StorageService.getUser().current_warehouse,
        destination_office_category: 'Stockist',
        is_pending_follow_up_delivery: true,
        has_stock_movement: true,
        is_packed: this.is_packed,
        search: this.search,
        fields: {
          orders:
            'origin_code,destination_code,origin_warehouse_code,warehouse_status,updatedAt,item_count',
        },
      };
    },
  },
  methods: {
    ...mapActions({
      fetchOrders: 'orders/fetchOrders',
    }),
    dayjs,
    debounceSearch: debounce(function () {
      this.fetchOrders({
        ...this.ordersParams,
      });
    }, 300),
    filterByPacked() {
      this.fetchOrders({
        ...this.ordersParams,
      });
    },
    onPageChangeProducts(page) {
      this.fetchOrders({
        ...this.ordersParams,
        pageNumber: page,
      });
    },
    redirectToDetail(data) {
      this.$router.push(`barang-ke-stockist/${data.id}`);
    },
  },
};
</script>
