<template>
  <div>
    <!-- Your component template here -->
  </div>
</template>

<script>
export default {
  name: 'GudangDashboard',
  props: {},
  data() {
    return {
      // Your component data here
    };
  },
  computed: {
    // Your computed properties here
  },
  components: {
    // Your child components here
  },
  methods: {
    // Your component methods here
  },
  created() {
    // Code to run when the component is created
  },
};
</script>

<style scoped>
/* Your component styles here */
</style>
