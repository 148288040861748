<template>
  <div class="mx-auto px-4 pb-12 sm:px-4 lg:px-8">
    <!-- Replace with your content -->
    <div>
      <div class="mt-8 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
        <div class="overflow-hidden rounded-lg bg-white shadow">
          <div class="relative p-4">
            <div class="flex items-center">
              <div class="w-0 flex-1">
                <div>
                  <div
                    class="flex items-center justify-between md:block lg:flex"
                  >
                    <div class="truncate text-sm font-medium text-gray-900">
                      Pengiriman Belum Terlaksana
                    </div>
                  </div>
                  <div class="mt-6">
                    <div class="text-lg font-bold text-gray-900">
                      {{ getOrders.data.length }}
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="absolute -bottom-4 -right-3 flex-shrink-0 text-gray-200"
              >
                <!-- Heroicon name: outline/scale -->
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-16 w-16"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    d="M5.5 16a3.5 3.5 0 01-.369-6.98 4 4 0 117.753-1.977A4.5 4.5 0 1113.5 16h-8z"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /End replace -->
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { StorageService } from '@/services/storage.service';

export default {
  components: {},

  data() {
    const destination_warehouse_id = StorageService.getUser().current_warehouse;
    return {
      destination_warehouse_id,
    };
  },
  computed: {
    ...mapGetters({
      isLoading: 'orders/getLoading',
      getOrders: 'orders/getOrders',
    }),
  },

  methods: {
    ...mapActions({
      fetchOrders: 'orders/fetchOrders',
    }),
  },
  created() {
    this.fetchOrders({
      // 'filter[order_type]': 'sale',
      'page[limit]': 5,
      'filter[order_status]': 'Diproses Gudang Pengiriman',
      'filter[destination_office_category_id]': 4,
      'filter[destination_warehouse_id]': this.destination_warehouse_id,
    });
  },
};
</script>
