<template>
  <OrderPurchaseForm />
</template>

<script>
import OrderPurchaseForm from '@/components/order/create/OrderPurchaseForm.vue';

export default {
  name: 'OrderPurchaseCreate',
  props: {},
  data() {
    return {
      // Your component data here
    };
  },
  computed: {
    // Your computed properties here
  },
  components: {
    OrderPurchaseForm,
    // Your child components here
  },
  methods: {
    // Your component methods here
  },
  created() {
    // Code to run when the component is created
  },
};
</script>

<style scoped>
/* Your component styles here */
</style>
