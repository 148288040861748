var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mx-auto px-4 sm:px-4 lg:px-8"},[_c('BaseCard',{attrs:{"title":"Penerimaan Barang dari Stockist Center"}},[_c('div',{staticClass:"flex items-center justify-end space-x-2"},[_c('base-select',{attrs:{"expand":false,"options":[
          {
            label: 'Semua',
            value: null,
          },
          {
            label: 'Diterima',
            value: true,
          },
          {
            label: 'Belum diterima',
            value: false,
          },
        ]},on:{"change":_vm.handleFilter},model:{value:(_vm.filter.isReceived),callback:function ($$v) {_vm.$set(_vm.filter, "isReceived", $$v)},expression:"filter.isReceived"}}),_c('base-search',{attrs:{"placeholder":"Cari kode atau nama"},on:{"input":_vm.handleSearch},model:{value:(_vm.filter.search),callback:function ($$v) {_vm.$set(_vm.filter, "search", $$v)},expression:"filter.search"}})],1),_c('div',{staticClass:"mt-2 flex sm:-mx-6 lg:-mx-8"},[_c('div',{staticClass:"inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"},[_c('StockMovementTable',{attrs:{"is-shipped":""},on:{"page-change":_vm.handleChangePage,"click-row":_vm.handleClickItem}})],1)]),_c('stock-movement-modal',{attrs:{"visible":_vm.visibleDetail,"description":"Data penerimaan barang dari Pusat","with-dispute":false},on:{"close":function($event){_vm.visibleDetail = false},"refresh":_vm.handleRefresh},scopedSlots:_vm._u([{key:"origin-office",fn:function({ originOffice }){return [_c('div',{staticClass:"sm:col-span-2"},[_c('dt',{staticClass:"text-sm font-bold text-gray-700"},[_vm._v("Nomor Pusat")]),_c('dd',{staticClass:"mt-1 text-sm text-gray-900"},[_vm._v(" "+_vm._s(originOffice.attributes.code)+" ")])])]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }