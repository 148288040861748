<script>
export default {
  name: 'OrderSummary',
  props: {
    totalPv: {
      type: Number,
      default: 0,
    },
    totalBv: {
      type: Number,
      default: 0,
    },
    grandTotal: {
      type: Number,
      default: 0,
    },
    totalWeight: {
      type: Number,
      default: 0,
    },
  },
};
</script>
<template>
  <div class="mb-5 rounded-lg bg-white p-4 sm:p-6 md:p-8">
    <div class="flex flex-col gap-y-4">
      <div class="flex flex-row gap-x-4">
        <div class="field-inset-default w-full bg-gray-100">
          <label for="total_pv" class="block text-xs font-bold text-gray-700">
            Total PV
          </label>
          <input
            type="text"
            name="total_pv"
            :value="totalPv | toCurrency"
            id="total_pv"
            placeholder="0"
            disabled="disabled"
            class="block w-full border-0 bg-gray-100 p-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
          />
        </div>
        <div class="field-inset-default w-full bg-gray-100">
          <label for="total_bv" class="block text-xs font-bold text-gray-700">
            Total BV
          </label>
          <input
            :value="totalBv | toCurrency"
            name="total_bv"
            id="total_bv"
            placeholder="0"
            disabled="disabled"
            class="input-inset-select bg-gray-100"
          />
        </div>
      </div>
      <div class="flex flex-row gap-x-4">
        <div class="field-inset-default w-full bg-gray-100">
          <label for="total_pv" class="block text-xs font-bold text-gray-700">
            Total Harga
          </label>
          <input
            type="text"
            name="grand_total"
            :value="grandTotal | toCurrency"
            id="grand_total"
            placeholder="0"
            disabled="disabled"
            class="block w-full border-0 bg-gray-100 p-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
          />
        </div>
        <div class="field-inset-default w-full bg-gray-100">
          <label
            for="total_berat"
            class="block text-xs font-bold text-gray-700"
          >
            Total Berat
          </label>
          <input
            :value="totalWeight"
            name="total_berat"
            id="total_berat"
            placeholder="0"
            disabled="disabled"
            class="input-inset-select bg-gray-100"
          />
        </div>
      </div>
    </div>
  </div>
</template>
