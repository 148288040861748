<template>
  <div class="mx-auto px-4 pb-12 sm:px-4 lg:px-8">
    <loading v-if="isLoading" />
    
    <div class="space-y-6">  
      <stock-summary-widget />
      <hr />
      <div class="rounded-lg">
        <div class="flex flex-col gap-4">
          <div class="flex items-center justify-end space-x-2">
            <base-search v-model="search" @input="onStocksSearch" />
            <export-dropdown />
          </div>
          <StockTable
            with-area-zero-price
            @detail="onStocksViewDetail"
            @page-changed="onStocksChangePage"
          />
        </div>
      </div>
    </div>

    <stock-detail-modal
      :visible="viewingStock"
      @close="viewingStock = false"
      @change-page-stock-trx="onStockTrxesChangePage"
      @change-period="onStockTrxesChangePeriod"
    >
      <template #attribute="{ product: stock }">
        <div class="sm:col-span-2">
          <dt class="text-sm font-bold text-gray-700">Kode Barang</dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ stock.data.attributes.product_code }}
          </dd>
        </div>
        <div class="sm:col-span-2">
          <dt class="text-sm font-bold text-gray-700">Nama Barang</dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ stock.data.attributes.product_name }}
          </dd>
        </div>
        <div class="sm:col-span-2">
          <dt class="text-sm font-bold text-gray-700">Satuan</dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ getSingleIncluded(stock, stock.data.relationships.product.data.id).attributes.unit_name }}
          </dd>
        </div>
        <div class="sm:col-span-2">
          <dt class="text-sm font-bold text-gray-700">Isi</dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ getSingleIncluded(stock, stock.data.relationships.product.data.id).attributes.volume }}
          </dd>
        </div>
        <div class="sm:col-span-2">
          <dt class="text-sm font-bold text-gray-700">Point Value (PV)</dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ getSingleIncluded(stock, stock.data.relationships.product.data.id).attributes.point | toCurrency }}
          </dd>
        </div>
        <div class="sm:col-span-2">
          <dt class="text-sm font-bold text-gray-700">Bonus Value (PV)</dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ getSingleIncluded(stock, stock.data.relationships.product.data.id).attributes.bonus | toCurrency }}
          </dd>
        </div>
        <div class="sm:col-span-2">
          <dt class="text-sm font-bold text-gray-700">Total Berat</dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ getSingleIncluded(stock, stock.data.relationships.product.data.id).attributes.weight | toCurrency }}
          </dd>
        </div>
        <div class="sm:col-span-2">
          <dt class="text-sm font-bold text-gray-700">Stok</dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ stock.data.attributes.stock_qty | toCurrency }}
          </dd>
        </div>
        <div class="sm:col-span-2">
          <dt class="text-sm font-bold text-gray-700">Status Order</dt>
          <dd class="mt-1 text-sm text-gray-900">
            <base-badge
              :color="
                stock.data.attributes.is_orderable ? 'blue' : 'yellow'
              "
            >
              {{
                stock.data.attributes.is_orderable ? 'Open' : 'Close'
              }}</base-badge
            >
          </dd>
        </div>
        <div class="sm:col-span-2">
          <dt class="text-sm font-bold text-gray-700">Status Pre Order</dt>
          <dd class="mt-1 text-sm text-gray-900">
            <base-badge
              :color="
                stock.data.attributes.is_pre_orderable
                  ? 'blue'
                  : 'yellow'
              "
            >
              {{
                stock.data.attributes.is_pre_orderable ? 'Open' : 'Close'
              }}</base-badge
            >
          </dd>
        </div>
        <div class="sm:col-span-2">
          <dt class="text-sm font-bold text-gray-700">Harga Stockist</dt>
          <dd class="mt-1 text-sm text-gray-900">{{ getProductPriceByBuyerType('Stockist')?.attributes?.price | toCurrency }}</dd>
        </div>
        <div class="sm:col-span-2">
          <dt class="text-sm font-bold text-gray-700">Harga Distributor</dt>
          <dd class="mt-1 text-sm text-gray-900">{{ getSingleIncluded(stock, stock.data.relationships.product.data.id).attributes.supplier_price | toCurrency }}</dd>
        </div>
        <div class="sm:col-span-2">
          <dt class="text-sm font-bold text-gray-700">Harga Konsumen</dt>
          <dd class="mt-1 text-sm text-gray-900">{{ getProductPriceByBuyerType('Konsumen')?.attributes?.price | toCurrency }}</dd>
        </div>
      </template>
    </stock-detail-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { debounce } from 'debounce';
import StockDetailModal from '@/components/stock/StockDetailModal.vue';
import StockTable from '@/components/stock/stock-table.vue';
import BaseSearch from '@/components/base/Search.vue';
import StockSummaryWidget from '@/components/stock/StockSummaryWidget.vue';
import ExportDropdown from '@/components/stock/ExportDropdown.vue';

export default {
  name: 'Stocks',
  components: {
    StockDetailModal,
    StockTable,
    BaseSearch,
    StockSummaryWidget,
    ExportDropdown,
  },
  data() {
    return {
      search: null,
      viewingStock: false,
      currentViewingStock: null
    };
  },
  computed: {
    ...mapGetters({
      getPeriod: 'periods/getPeriod',
      getStock: 'stocks/getStock',
      getStocksLoading: 'stocks/getLoading',
      getStockTrxesLoading: 'stock_trxes/getLoading',
      getPeriodsLoading: 'periods/getLoading',
      getLoadingTotalStockAvailable: 'stocks/getLoadingTotalStockAvailable',
      getLoadingProductPrices: 'product_prices/getLoading',
      getProductPrices: 'product_prices/getProductPrices',
      me: 'auth/getUser',
    }),
    isLoading() {
      return (
        this.getStockTrxesLoading ||
        this.getStocksLoading ||
        this.getPeriodsLoading ||
        this.getLoadingTotalStockAvailable ||
        this.getLoadingProductPrices
      );
    },
  },
  methods: {
    ...mapActions({
      fetchStocksByWarehouse: 'stocks/fetchStocksByWarehouse',
      fetchWarehouseById: 'warehouses/fetchWarehouseById',
      fetchStockById: 'stocks/fetchStockById',
      fetchStockTrxesByStock: 'stock_trxes/fetchStockTrxesByStock',
      fetchCurrentPeriod: 'periods/fetchCurrentPeriod',
      fetchProductPrices: 'product_prices/fetchProductPrices'
    }),
    getProductPriceByBuyerType(buyerType) {
      return this.getProductPrices.data.find(productPrice => productPrice.attributes['buyer-type'] === buyerType)
    },
    loadStocks(params) {
      this.fetchStocksByWarehouse({
        'warehouse_id': this.me.current_warehouse,
        'filter[search]': this.search,
        'page[number]': 1,
        'page[size]': 5,
        'include': 'product.product-prices',
        'fields[stocks]':
          'product_code,product_name,stock_qty,stock_ordered,stock_real,product,free_qty,free_ordered,free_real',
        ...params,
      });
    },
    async loadStockTrxes(params) {
      await this.fetchStockTrxesByStock({
        stockId: this.currentViewingStock.id,
        params: {
          params: {
            'page[size]': 5,
            'page[number]': 1,
            sort: 'latest',
            'filter[period_uuid]': this.getPeriod.data.id,
            ...params
          },
        },
      });
    },
    async loadViewedStock() {
      await this.fetchStockById({
        id: this.currentViewingStock.id,
        params: {
          include: 'product',
          'fields[stocks]': 'product_code,product_name,stock_qty,is_orderable,is_pre_orderable,product',
          'fields[products]': 'unit_name,volume,point,bonus,weight,supplier_price'
        },
      })
      await this.fetchProductPrices({
        productId: this.getStock.data.relationships.product.data.id,
        params: {
          'fields[product-prices]': 'buyer-type,price',
          'filter[buyer-type-in]': 'Stockist,Konsumen'
        }
      })
    },
    onStocksChangePage(page) {
      this.loadStocks({
        'page[number]': page,
        'page[size]': 5,
      });
    },
    onStocksSearch: debounce(function () {
      this.loadStocks();
    }, 300),
    async onStocksViewDetail(stock) {
      this.currentViewingStock = stock;

      await Promise.all([
        this.loadViewedStock(),
        this.loadStockTrxes(),
      ]);

      this.viewingStock = !this.viewingStock;
    },
    onStockTrxesChangePeriod(periodUuid) {
      this.loadStockTrxes({
        'filter[period_uuid]': periodUuid
      });
    },
    onStockTrxesChangePage(page) {
      this.loadStockTrxes({
        'page[number]': page
      });
    },
  },
  created() {
    this.loadStocks();
    this.fetchCurrentPeriod()
  },
};
</script>
