<template>
  <div class="mx-4 py-4 md:mx-6 md:py-6">
    <div v-if="isLoading" class="justify-center text-center">
      <loading></loading>
    </div>
    <div class="space-y-4" v-else>
      <BaseCard title="Detail Penjualan">
        <template #action>
          <base-badge color="purple">
            {{ dataPenjualan.warehouse_status }}
          </base-badge>
        </template>

        <div class="py-4">
          <dl class="flex flex-row gap-x-4">
            <div class="w-full">
              <dt class="text-sm font-bold text-gray-700">No Penjualan</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ dataPenjualan.no_penjualan }}
              </dd>
            </div>
            <div class="w-full">
              <dt class="text-sm font-bold text-gray-700">Tanggal</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ dayjs(dataPenjualan.tanggal).format('ll LT') }}
              </dd>
            </div>
            <div class="w-full">
              <dt class="text-sm font-bold text-gray-700">Mitra Usaha</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ dataPenjualan.kode_stockist }}
              </dd>
            </div>
          </dl>
          <dl class="flex mt-6 flex-row gap-x-4">
            <div class="w-full">
              <dt class="text-sm font-bold text-gray-700">Nama Mitra</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ dataPenjualan.nama_pemilik }}
              </dd>
            </div>
            <div class="w-full">
              <dt class="text-sm font-bold text-gray-700">Alamat Mitra 1</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ dataPenjualan.alamat }}
              </dd>
            </div>
            <div class="w-full">
              <dt class="text-sm font-bold text-gray-700">Alamat Mitra 2</dt>
              <dd class="mt-1 text-sm text-gray-900">
                <!-- {{dataPenjualan.relationships['destination-office'].data ? getRelationships(dataPenjualan.relationships['destination-office'].data.id).attributes.address2 : '-'}} -->
              </dd>
            </div>
          </dl>
        </div>
      </BaseCard>
      <BaseCard
        v-if="dataPenjualan.faktur_pengemasan"
        title="Informasi Kemasan"
        description="Daftar kemasan yang akan dikirim ke Mitra"
      >
        <div class="mb-8 grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-12">
          <div class="sm:col-span-10">
            <div class="field-inset-default w-full bg-gray-100">
              <label
                for="kode_po"
                class="block text-xs font-bold text-gray-700"
              >
                Nama Kemasan
              </label>
              <p
                v-if="dataPenjualan.faktur_pengemasan"
                class="block w-full border-0 p-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
              >
                {{ dataPenjualan.faktur_pengemasan.attributes.code }}
              </p>
            </div>
          </div>
          <div class="sm:col-span-1">
            <button
              @click="
                $router.push(
                  `/gudang/pengeluaran/${dataPenjualan.id}/pengemasan`
                )
              "
              class="field-inset-default w-full border border-gray-500 text-gray-500"
            >
              <div class="flex items-center justify-center py-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5 text-gray-400"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                  />
                </svg>
                <p class="ml-2 text-xs text-gray-400">Edit</p>
              </div>
            </button>
          </div>
          <div class="sm:col-span-1">
            <button
              class="field-inset-default w-full border border-gray-500 text-gray-500"
            >
              <div class="flex items-center justify-center py-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5 text-gray-400"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5 4v3H4a2 2 0 00-2 2v3a2 2 0 002 2h1v2a2 2 0 002 2h6a2 2 0 002-2v-2h1a2 2 0 002-2V9a2 2 0 00-2-2h-1V4a2 2 0 00-2-2H7a2 2 0 00-2 2zm8 0H7v3h6V4zm0 8H7v4h6v-4z"
                    clip-rule="evenodd"
                  />
                </svg>
                <p class="ml-2 text-xs text-gray-400">Cetak</p>
              </div>
            </button>
          </div>
        </div>
        <Datatable :paginated="false" :footer="false" class="mt-2">
          <template v-slot:thead>
            <thead class="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  Kode Koli
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  Jumlah Produk
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  Aksi
                </th>
              </tr>
            </thead>
          </template>
          <template v-slot:tbody>
            <tbody>
              <tr
                class="bg-white"
                v-for="(koli, index) in dataPenjualan.faktur_pengemasan
                  .attributes.packets"
                :key="index"
              >
                <td
                  @click="detailModal(koli)"
                  class="cursor-pointer whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                >
                  {{ koli.name }}
                </td>
                <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                  {{ koli.products.length }}
                </td>
                <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                  <div class="flex m-auto space-x-2">
                    <button type="button">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M5 4v3H4a2 2 0 00-2 2v3a2 2 0 002 2h1v2a2 2 0 002 2h6a2 2 0 002-2v-2h1a2 2 0 002-2V9a2 2 0 00-2-2h-1V4a2 2 0 00-2-2H7a2 2 0 00-2 2zm8 0H7v3h6V4zm0 8H7v4h6v-4z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </Datatable>
      </BaseCard>

      <InformasiBarangKeluar />

      <div class="py-8">
        <div class="flex justify-between">
          <div v-if="dataPenjualan.faktur_pengemasan">
            <div class="flex space-x-2">
              <base-button
                color="white"
                v-if="
                  !dataPenjualan.is_packed &&
                  !dataPenjualan.faktur_pengemasan.attributes
                    .is_valid_for_shipment
                "
                @click="cancelConfirmation = !cancelConfirmation"
                type="button"
              >
                Batalkan Faktur Penjualan
              </base-button>
              <base-button
                color="white"
                v-if="
                  dataPenjualan.faktur_pengemasan &&
                  !dataPenjualan.faktur_pengemasan.attributes
                    .is_valid_for_shipment
                "
                @click="
                  cancelStockMovementConfirmation =
                    !cancelStockMovementConfirmation
                "
                type="button"
              >
                Batalkan Faktur Kemasan
              </base-button>
            </div>
          </div>
          <div v-else>
            <div class="flex space-x-2">
              <base-button
                color="white"
                v-if="!dataPenjualan.is_packed"
                @click="cancelConfirmation = !cancelConfirmation"
                type="button"
              >
                Batalkan Faktur Penjualan
              </base-button>
              <base-button
                color="white"
                v-if="dataPenjualan.faktur_pengemasan"
                @click="
                  cancelStockMovementConfirmation =
                    !cancelStockMovementConfirmation
                "
                type="button"
              >
                Batalkan Faktur Kemasan
              </base-button>
            </div>
          </div>
          <div v-if="dataPenjualan.faktur_pengemasan">
            <base-button
              v-if="
                dataPenjualan.faktur_pengemasan &&
                !dataPenjualan.faktur_pengemasan.attributes
                  .is_valid_for_shipment
              "
              @click="openModalValidation"
            >
              Cetak dan Kirim!
            </base-button>
            <base-button
              v-if="
                !dataPenjualan.is_packed &&
                !dataPenjualan.faktur_pengemasan &&
                dataPenjualan.print_invoice_enabled
              "
              @click="createInvoiceConfirmation = true"
            >
              Cetak Faktur Penjualan
            </base-button>
            <base-button color="white" to="/gudang/pengeluaran">
              Kembali
            </base-button>
          </div>
          <div v-else>
            <base-button
              v-if="
                !dataPenjualan.is_packed && !dataPenjualan.print_invoice_enabled
              "
              @click="sendWithoutKoli"
            >
              Diproses ke Pengiriman
            </base-button>
            <base-button
              v-if="
                !dataPenjualan.is_packed && dataPenjualan.print_invoice_enabled
              "
              @click="createInvoiceConfirmation = true"
            >
              Cetak Faktur Penjualan
            </base-button>
            <base-button color="white" to="/gudang/pengeluaran">
              Kembali
            </base-button>
          </div>
        </div>
      </div>

      <!-- Detail Koli -->
      <transition
        name="customFade"
        enter-active-class="fadeIn"
        leave-active-class="fadeOut"
      >
        <div
          v-if="openDetail"
          style="animation-duration: 0.3s"
          class="fixed inset-0 z-10 mx-4"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div
            class="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
          >
            <div
              class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>
            <span
              class="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
              >&#8203;</span
            >
            <div
              class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-6xl sm:p-6 sm:align-middle"
            >
              <div class="flex mb-8 justify-between">
                <div class="flex items-center space-x-4">
                  <div>
                    <h3 class="text-lg font-bold leading-6 text-gray-900">
                      Detail Order Barang
                    </h3>
                    <p class="mt-1 max-w-2xl text-sm text-gray-500">
                      Data Order Barang
                    </p>
                  </div>
                </div>
                <div>
                  <img
                    src="@/assets/images/logo.png"
                    alt=""
                    class="flex justify-end"
                  />
                </div>
              </div>
              <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                <dl class="flex flex-row gap-x-4">
                  <div class="w-full">
                    <dt class="text-sm font-bold text-gray-700">Nama Koli</dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      {{ detailKoli.name }}
                    </dd>
                  </div>
                  <div class="w-full">
                    <dt class="text-sm font-bold text-gray-700">Kode Koli</dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      {{ detailKoli.code }}
                    </dd>
                  </div>
                </dl>
                <dl class="flex mt-6 flex-row gap-x-4">
                  <div class="w-full">
                    <dt class="text-sm font-bold text-gray-700">
                      Daftar Produk
                    </dt>
                  </div>
                </dl>
                <div class="my-6 sm:col-span-6">
                  <Datatable :paginated="false" :footer="false" class="mt-4">
                    <template v-slot:thead>
                      <thead class="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            KODE BARANG
                          </th>
                          <th
                            scope="col"
                            class="px-6 py-3 pr-12 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            JUMLAH
                          </th>
                          <th
                            scope="col"
                            class="px-6 py-3 pr-12 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            HARGA
                          </th>
                          <th
                            scope="col"
                            class="px-6 py-3 pr-12 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            BERAT
                          </th>
                          <th
                            scope="col"
                            class="px-6 py-3 pr-12 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            TOTAL HARGA
                          </th>
                          <th
                            scope="col"
                            class="px-6 py-3 pr-12 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            TOTAL BERAT
                          </th>
                        </tr>
                      </thead>
                    </template>
                    <template v-slot:tbody>
                      <tbody>
                        <tr
                          class="bg-white"
                          v-for="(barang, index) in detailKoli.products"
                          :key="index"
                        >
                          <td
                            class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                          >
                            {{ barang.product_code }}
                          </td>
                          <td
                            class="whitespace-nowrap px-6 py-4 pr-12 text-right text-sm text-gray-500"
                          >
                            {{ barang.shipped_product_qty }}
                          </td>
                          <td
                            class="whitespace-nowrap px-6 py-4 pr-12 text-right text-sm text-gray-500"
                          >
                            {{ barang.product_price | toCurrency }}
                          </td>
                          <td
                            class="whitespace-nowrap px-6 py-4 pr-12 text-right text-sm text-gray-500"
                          >
                            {{ barang.product_weight }}
                          </td>
                          <td
                            class="whitespace-nowrap px-6 py-4 pr-12 text-right text-sm text-gray-500"
                          >
                            {{
                              (barang.product_price *
                                barang.shipped_product_qty)
                                | toCurrency
                            }}
                          </td>
                          <td
                            class="whitespace-nowrap px-6 py-4 pr-12 text-right text-sm text-gray-500"
                          >
                            {{
                              barang.total_weight * barang.shipped_product_qty
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </Datatable>
                </div>
                <div class="flex">
                  <div class="flex w-2/3 flex-col gap-y-2"></div>
                  <div class="flex w-1/3 flex-col gap-y-2">
                    <div class="inline-flex w-full justify-between"></div>
                    <div class="inline-flex w-full justify-between"></div>
                  </div>
                </div>
              </div>
              <div class="flex justify-end">
                <base-button
                  @click="openDetail = !openDetail"
                  type="button"
                  fullwidth
                >
                  Tutup
                </base-button>
              </div>
            </div>
          </div>
        </div>
      </transition>

      <!-- MODAL CONFIRMATION-->
      <transition
        name="customFade"
        enter-active-class="fadeIn"
        leave-active-class="fadeOut"
      >
        <div
          v-if="createInvoiceConfirmation"
          style="animation-duration: 0.3s"
          class="fixed inset-0 z-10 print:hidden"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div
            class="flex mx-4 min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
          >
            <div
              class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>
            <span
              class="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
              >&#8203;</span
            >
            <div
              class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle"
            >
              <form @submit.prevent="createInvoice">
                <div>
                  <div
                    class="flex mx-auto h-12 w-12 items-center justify-center rounded-full bg-green-100"
                  >
                    <!-- Heroicon name: outline/check -->
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-6 w-6 text-green-600"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  </div>
                  <div class="mt-3 text-center sm:mt-5">
                    <h3
                      class="text-lg font-medium leading-6 text-gray-900"
                      id="modal-title"
                    >
                      Buat Faktur
                    </h3>
                    <div class="mt-2">
                      <p class="text-sm text-gray-500">
                        Apakah anda yakin data yang dibuat sudah benar?
                      </p>
                    </div>
                  </div>
                </div>
                <div class="mt-5 sm:mt-6 sm:flex sm:flex-row sm:gap-x-3">
                  <base-button type="submit" fullwidth> Ya </base-button>
                  <base-button
                    color="white"
                    @click="
                      createInvoiceConfirmation = !createInvoiceConfirmation
                    "
                    type="button"
                    fullwidth
                  >
                    Tutup
                  </base-button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </transition>

      <!-- MODAL VALIDASI KE PENGIRIMAN-->
      <transition
        name="customFade"
        enter-active-class="fadeIn"
        leave-active-class="fadeOut"
      >
        <div
          v-if="validateConfirmation"
          style="animation-duration: 0.3s"
          class="fixed inset-0 z-10 print:hidden"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div
            class="flex mx-4 min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
          >
            <div
              class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>
            <span
              class="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
              >&#8203;</span
            >
            <div
              class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle"
            >
              <form @submit.prevent="validateToShipment">
                <div>
                  <div
                    class="flex mx-auto h-12 w-12 items-center justify-center rounded-full bg-green-100"
                  >
                    <!-- Heroicon name: outline/check -->
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-6 w-6 text-green-600"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  </div>
                  <div class="mt-3 text-center sm:mt-5">
                    <h3
                      class="text-lg font-medium leading-6 text-gray-900"
                      id="modal-title"
                    >
                      Validasi Ke Pengiriman
                    </h3>
                    <div class="mt-2">
                      <p class="text-sm text-gray-500">
                        Apakah anda yakin data yang dibuat sudah benar?
                      </p>
                    </div>
                  </div>
                </div>
                <div class="mt-5 sm:mt-6 sm:flex sm:flex-row sm:gap-x-3">
                  <base-button type="submit" fullwidth> Ya </base-button>
                  <base-button
                    color="white"
                    @click="validateConfirmation = !validateConfirmation"
                    type="button"
                    fullwidth
                  >
                    Tutup
                  </base-button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </transition>

      <!-- MODAL CONFIRMATION-->
      <transition
        name="customFade"
        enter-active-class="fadeIn"
        leave-active-class="fadeOut"
      >
        <div
          v-if="cancelConfirmation"
          style="animation-duration: 0.3s"
          class="fixed inset-0 z-10"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div
            class="flex mx-4 min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
          >
            <div
              class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>
            <span
              class="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
              >&#8203;</span
            >
            <div
              class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle"
            >
              <form @submit.prevent="cancelOrder">
                <div>
                  <div
                    class="flex mx-auto h-12 w-12 items-center justify-center rounded-full bg-red-100"
                  >
                    <!-- Heroicon name: outline/check -->
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-6 w-6 text-red-600"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </div>
                  <div class="mt-3 text-center sm:mt-5">
                    <h3
                      class="text-lg font-medium leading-6 text-gray-900"
                      id="modal-title"
                    >
                      Batalkan Faktur Penjualan
                    </h3>
                    <div class="mt-2">
                      <p class="text-sm text-gray-500">
                        {{
                          dataPenjualan.faktur_pengemasan
                            ? 'Sudah ada faktur pengemasan. Anda yakin ingin membatalkan?'
                            : 'Anda yakin ingin membatalkan?'
                        }}
                      </p>
                    </div>
                  </div>
                  <div class="pt-4">
                    <label
                      for="comment"
                      class="block text-sm font-medium text-gray-700"
                      >Alasan pembatalan</label
                    >
                    <div class="mt-1">
                      <textarea
                        v-model="cancel_description"
                        rows="4"
                        name="comment"
                        id="comment"
                        required
                        class="block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div class="mt-5 sm:mt-6 sm:flex sm:flex-row sm:gap-x-3">
                  <base-button type="submit" fullwidth> Ya </base-button>
                  <base-button
                    color="white"
                    @click="cancelConfirmation = !cancelConfirmation"
                    type="button"
                    fullwidth
                  >
                    Tutup
                  </base-button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </transition>

      <!-- MODAL BATAL FAKTUR PENGEMASAN -->
      <transition
        name="customFade"
        enter-active-class="fadeIn"
        leave-active-class="fadeOut"
      >
        <div
          v-if="cancelStockMovementConfirmation"
          style="animation-duration: 0.3s"
          class="fixed inset-0 z-10"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div
            class="flex mx-4 min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
          >
            <div
              class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>
            <span
              class="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
              >&#8203;</span
            >
            <div
              class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle"
            >
              <form @submit.prevent="cancelStockMovement">
                <div>
                  <div
                    class="flex mx-auto h-12 w-12 items-center justify-center rounded-full bg-red-100"
                  >
                    <!-- Heroicon name: outline/check -->
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-6 w-6 text-red-600"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </div>
                  <div class="mt-3 text-center sm:mt-5">
                    <h3
                      class="text-lg font-medium leading-6 text-gray-900"
                      id="modal-title"
                    >
                      Batalkan Faktur Pengemasan
                    </h3>
                    <div class="mt-2">
                      <p class="text-sm text-gray-500">
                        Sudah ada faktur pengemasan. Anda yakin ingin
                        membatalkan?
                      </p>
                    </div>
                  </div>
                </div>
                <div class="mt-5 sm:mt-6 sm:flex sm:flex-row sm:gap-x-3">
                  <base-button type="submit" fullwidth> Ya </base-button>
                  <base-button
                    @click="
                      cancelStockMovementConfirmation =
                        !cancelStockMovementConfirmation
                    "
                    type="button"
                    fullwidth
                  >
                    Tutup
                  </base-button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </transition>

      <!-- MODAL CONFIRMATION WAREHOUSE-->
      <transition
        name="customFade"
        enter-active-class="fadeIn"
        leave-active-class="fadeOut"
      >
        <div
          v-if="confirmationWarehouse"
          style="animation-duration: 0.3s"
          class="fixed inset-0 z-10"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div
            class="flex mx-4 min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
          >
            <div
              class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>
            <span
              class="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
              >&#8203;</span
            >
            <div
              class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle"
            >
              <form @submit.prevent="cancelReceipt">
                <div>
                  <div
                    class="flex mx-auto h-12 w-12 items-center justify-center rounded-full bg-red-100"
                  >
                    <!-- Heroicon name: outline/check -->
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-6 w-6 text-red-600"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </div>
                  <div class="mt-3 text-center sm:mt-5">
                    <h3
                      class="text-lg font-medium leading-6 text-gray-900"
                      id="modal-title"
                    >
                      Batalkan Faktur Gudang
                    </h3>
                    <div class="mt-2">
                      <p class="text-sm text-gray-500">
                        Anda yakin ingin membatalkan faktur
                        <span class="font-semibold">{{
                          stock_movements.attributes.code
                        }}</span
                        >?
                      </p>
                    </div>
                  </div>
                  <div class="pt-4">
                    <label
                      for="comment"
                      class="block text-sm font-medium text-gray-700"
                      >Alasan pembatalan</label
                    >
                    <div class="mt-1">
                      <textarea
                        v-model="cancel_description"
                        rows="4"
                        name="comment"
                        id="comment"
                        required
                        class="block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div class="mt-5 sm:mt-6 sm:flex sm:flex-row sm:gap-x-3">
                  <base-button type="submit" fullwidth> Ya </base-button>
                  <base-button
                    @click="confirmationWarehouse = !confirmationWarehouse"
                    type="button"
                    color="white"
                    fullwidth
                  >
                    Tutup
                  </base-button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import dayjs from 'dayjs';
import Loading from '@/components/Loading.vue';
import Datatable from '@/components/base/Datatable';
import InformasiBarangKeluar from '@/components/pengeluaran/informasi-barang-keluar.vue';

export default {
  name: 'DetailPengeluaran',
  components: {
    Datatable,
    Loading,
    InformasiBarangKeluar,
  },
  data() {
    return {
      is_detail: false,
      is_shipped: false,
      modal: null,
      createInvoiceConfirmation: false,
      cancelConfirmation: false,
      cancelStockMovementConfirmation: false,
      confirmationWarehouse: false,
      validateConfirmation: false,
      warningConfirmation: false,
      removePacketConfirmation: false,
      success: false,
      failed: false,
      openDetail: false,
      openListOrders: false,
      cancel_description: null,
      cancel_warehouse_description: null,
      dataPenjualan: {
        id: '',
        no_penjualan: null,
        origin_warehouse_id: null,
        destination_warehouse_id: null,
        origin_office_id: null,
        destination_office_id: null,
        kode_stockist: null,
        nama_pemilik: null,
        kode_gudang: null,
        wilayah_harga: null,
        saldo_awal: null,
        total_penjualan: null,
        has_warehouse_receipt: false,
        metode: null,
        is_packed: null,
        is_paid: null,
        print_invoice_enabled: null,
        faktur_gudang: [],
        order_details: [],
        faktur_pengemasan: null,
        no_resi: null,
        taken_by: null,
        expedition: null,
      },
      stock_movements: [],
      packet: {},
      detailKoli: null,
    };
  },
  created() {
    if (this.$route.params.id) {
      this.is_detail = true;
      this.dataPenjualan.id = this.$route.params.id;
      this.setOrder(this.dataPenjualan.id);
    }
    const handleEscape = () => {
      this.success = false;
      this.failed = false;
    };
    this.setEscape(handleEscape);
  },
  computed: {
    ...mapGetters({
      isLoading: 'orders/getLoading',
      getOrders: 'orders/getOrders',
      getOrder: 'orders/getOrder',
      getError: 'orders/getError',
      getExpeditionsByOffice: 'expeditions/getExpeditionsByOffice',
    }),
    selisihPembayaran() {
      return (
        (this.dataPenjualan.total_penjualan || 0) -
        this.sum(this.metodePembayaran, 'nominalPembayaran')
      );
    },
  },
  methods: {
    dayjs,
    ...mapActions({
      fetchProducts: 'products/fetchProducts',
      fetchOrders: 'orders/fetchOrders',
      fetchOrder: 'orders/fetchOrder',
      updateOrder: 'orders/updateOrder',
      createOrder: 'orders/createOrder',
      cancelOrderFromWarehouse: 'orders/cancelOrderFromWarehouse',
      createStockMovement: 'stock_movements/createStockMovement',
      updateStockMovement: 'stock_movements/updateStockMovement',
      sendStockMovement: 'stock_movements/sendStockMovement',
      deletePacket: 'packets/deletePacket',
      deleteStockMovement: 'stock_movements/deleteStockMovement',
    }),
    setOrder(id) {
      this.fetchOrder({
        id: id,
        include: 'area,buyer-type,destination-office,order-details,order-detail-bonuses,origin-office,stock-movements,origin-warehouse,destination-warehouse,payments'
      }).then((response) => {
        this.dataPenjualan.no_penjualan =
          response.data.data.attributes.origin_code;
        this.dataPenjualan.has_warehouse_receipt =
          response.data.data.attributes.has_warehouse_receipt;
        this.dataPenjualan.origin_warehouse_id =
          response.data.data.relationships['origin-warehouse'].data.id;
        this.dataPenjualan.destination_warehouse_id =
          response.data.data.relationships['destination-warehouse'].data.id;
        this.dataPenjualan.origin_office_id =
          response.data.data.relationships['origin-office'].data.id;
        this.dataPenjualan.destination_office_id =
          response.data.data.relationships['destination-office'].data.id;
        this.dataPenjualan.kode_stockist = this.getRelationships(
          response.data,
          response.data.data.relationships['destination-office'].data.id
        ).attributes.code;
        this.dataPenjualan.nama_pemilik = this.getRelationships(
          response.data,
          response.data.data.relationships['destination-office'].data.id
        ).attributes.name;
        this.dataPenjualan.total_penjualan =
          response.data.data.attributes.final_price -
          parseInt(response.data.data.attributes.payment_amount);
        this.dataPenjualan.alamat = this.getRelationships(
          response.data,
          response.data.data.relationships['destination-office'].data.id
        ).attributes.address;
        this.dataPenjualan.order_shipment =
          response.data.data.attributes.order_shipment;
        this.dataPenjualan.is_packed = response.data.data.attributes.is_packed;
        this.dataPenjualan.is_paid = response.data.data.attributes.is_paid;
        this.dataPenjualan.print_invoice_enabled =
          response.data.data.attributes.print_invoice_enabled;
        this.dataPenjualan.warehouse_status =
          response.data.data.attributes.warehouse_status;
        this.dataPenjualan.order_details = this.getOrder.included
          .filter((it) => it.type === 'order-details')
          .map((it) => it.attributes);
        this.dataPenjualan.faktur_pengemasan = response.data.data.relationships[
          'stock-movements'
        ].data[0]
          ? this.getRelationships(
              response.data,
              response.data.data.relationships['stock-movements'].data[0].id
            )
          : null;
        // this.dataPenjualan.faktur_pengemasan = response.data.data.relationships['stock-movements'].data.map((move) => {
        //   return this.getRelationships(response.data, move.id)
        // })
      });
    },
    successModal() {
      this.success = !this.success;
      this.clearData();
    },
    failedModal() {
      this.failed = !this.failed;
      this.clearData();
    },
    detailModal(koli) {
      this.openDetail = !this.openDetail;
      this.detailKoli = koli;
    },
    openWarehouseConfirmation(stock_movements) {
      this.confirmationWarehouse = !this.confirmationWarehouse;
      this.stock_movements = stock_movements;
    },
    clearData() {
      this.dataPenjualan = {
        id: '',
        no_penjualan: null,
        origin_warehouse_id: null,
        destination_warehouse_id: null,
        origin_office_id: null,
        destination_office_id: null,
        kode_stockist: null,
        nama_pemilik: null,
        kode_gudang: null,
        wilayah_harga: null,
        saldo_awal: null,
        total_penjualan: null,
        has_warehouse_receipt: false,
        metode: null,
        is_packed: null,
        print_invoice_enabled: null,
        faktur_gudang: [],
        order_details: [],
        faktur_pengemasan: null,
        no_resi: null,
        taken_by: null,
        expedition: null,
      };
      (this.metodePembayaran = [
        {
          jenisPembayaran: null,
          nominalPembayaran: null,
        },
      ]),
        (this.cancel_description = null);
      this.cancel_warehouse_description = null;
      this.stock_movements = null;
      this.detailKoli = null;
    },
    cancelPayment() {
      const payload = {
        data: {
          type: 'payments',
          id: this.invoice.id,
          attributes: {
            deletedAt: new Date(),
          },
        },
      };

      this.updatePayment(payload).then((response) => {
        if (response) {
          this.confirmationPayment = !this.confirmationPayment;
          this.setOrder(this.dataPenjualan.id);
        } else {
          this.failed = true;
        }
      });
    },
    cancelOrder() {
      const payload = {
        data: {
          type: 'orders',
          id: this.dataPenjualan.id,
          attributes: {
            cancel_warehouse_description: this.cancel_description,
          },
        },
      };
      this.cancelOrderFromWarehouse(payload).then((response) => {
        if (response) {
          this.$router.push('/gudang/pengeluaran');
        } else {
          this.failed = true;
        }
      });
    },
    cancelStockMovement() {
      this.deleteStockMovement({
        id: this.dataPenjualan.faktur_pengemasan.id,
      }).then((response) => {
        if (response) {
          this.cancelStockMovementConfirmation = false;
          this.setOrder(this.dataPenjualan.id);
        }
      });
    },
    createInvoice() {
      const payload = {
        data: {
          type: 'orders',
          id: this.dataPenjualan.id,
          attributes: {
            print_invoice_enabled: false,
          },
        },
      };
      this.updateOrder(payload).then((response) => {
        if (response) {
          this.createInvoiceConfirmation = false;

          setTimeout(() => {
            window.print();
            this.$router.push('/gudang/pengeluaran');
          }, 0);
        } else {
          this.failed = true;
        }
      });
    },
    cancelReceipt() {
      const payload = {
        data: {
          type: 'stock-movements',
          id: this.stock_movements.id,
          attributes: {
            deletedAt: new Date(),
          },
        },
      };

      this.updateStockMovement(payload).then((response) => {
        if (response) {
          this.confirmationWarehouse = !this.confirmationWarehouse;
          this.$router.push('/gudang/pengeluaran');
        } else {
          this.failed = true;
        }
      });
    },
    sendOrder() {
      const payload = {
        data: {
          type: 'stock-movements',
          id: this.dataPenjualan.faktur_gudang[0].id,
          attributes: {
            is_shipped: true,
          },
        },
      };
      this.updateStockMovement(payload).then((response) => {
        if (response) {
          this.$router.push('/gudang/pengeluaran');
        }
        this.openDetail = false;
      });
    },
    getRelationships(resource, id) {
      let data = resource.included.filter(function (el) {
        return el.id === id;
      });
      return data[0];
    },
    sum(data, key) {
      return data.reduce((a, b) => a + parseFloat(b[key] || 0), 0);
    },
    getOrderDetails(params) {
      let dataDetail = [];
      params.map((param) => {
        let data = {
          product_id: param.product_id,
          product_qty: param.product_not_recieved,
          no_batch: param.no_batch,
          expired_at: param.expired_at,
        };
        dataDetail.push(data);
      });
      return dataDetail;
    },
    openRemovePacket(data) {
      this.removePacketConfirmation = true;
      this.packet = data;
    },
    removePacket() {
      this.deletePacket({
        id: this.packet.uuid,
      }).then((response) => {
        if (response) {
          this.removePacketConfirmation = false;
          this.setOrder(this.dataPenjualan.id);
        }
      });
    },
    openModalValidation() {
      const notPacked = this.dataPenjualan.order_details.reduce(
        (prev, current) => prev + current.product_not_packed,
        0
      );
      const shipped =
        this.dataPenjualan.faktur_pengemasan.attributes.packets.reduce(
          (prev, current) => {
            const shipped = current.products.reduce(
              (prev, current) => prev + current.shipped_product_qty,
              0
            );

            return prev + shipped;
          },
          0
        );

      if (notPacked === shipped) {
        this.validateConfirmation = true;
      } else {
        this.warningConfirmation = true;
      }
    },
    validateToShipment() {
      const payload = {
        data: {
          type: 'orders',
          id: this.dataPenjualan.id,
          attributes: {
            is_valid_for_shipment: true,
          },
        },
      };
      this.updateOrder(payload).then((response) => {
        if (response) {
          setTimeout(() => {
            window.print();
            this.$router.push('/gudang/pengeluaran');
          }, 0);
        } else {
          this.failed = true;
        }
      });
    },
    validate() {
      const payload = {
        data: {
          type: 'stock-movements',
          id: this.dataPenjualan.faktur_pengemasan.id,
          attributes: {
            is_valid_for_shipment: true,
          },
        },
      };
      this.updateStockMovement(payload).then((response) => {
        if (response) {
          this.validateConfirmation = false;
          this.warningConfirmation = false;

          setTimeout(() => {
            window.print();
            this.$router.push('/gudang/pengeluaran');
          }, 0);
        }
      });
    },
    redirectToFollowDelivery() {
      this.$router.push('/gudang/susulan');
    },
    sendWithoutKoli() {
      const payload = {
        data: {
          type: 'stock-movements',
          attributes: {
            origin_warehouse_id: this.dataPenjualan.origin_warehouse_id,
            destination_warehouse_id:
              this.dataPenjualan.destination_warehouse_id,
            origin_office_id: this.dataPenjualan.origin_office_id,
            destination_office_id: this.dataPenjualan.destination_office_id,
            is_valid_for_shipment: true,

            packets: [
              {
                code: null,
                name: 'Koli 1',
                products: [
                  ...this.getIncludedByType(this.getOrder, 'order-details'),
                  ...this.getIncludedByType(this.getOrder, 'order-detail-bonuses')
                ].map(orderDetailOrBonus => ({
                  product_id: orderDetailOrBonus.attributes.product_id,
                  shipped_product_qty: orderDetailOrBonus.type === 'order-details' ? orderDetailOrBonus.attributes.product_qty : orderDetailOrBonus.attributes.bonus_qty,
                  is_bonus: orderDetailOrBonus.type === 'order-detail-bonuses'
                }))
              },
            ],
          },
          relationships: {
            order: {
              data: {
                type: 'orders',
                id: this.dataPenjualan.id,
              },
            },
          },
        },
      };
      this.createStockMovement(payload).then((response) => {
        if (response) {
          this.$router.go(-1);
        } else {
          this.failed = true;
        }
      });
      // }
    },
  },
};
</script>
