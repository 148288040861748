<template>
  <div class="mx-auto px-4 pb-12 sm:px-4 lg:px-8">
    <div class="mb-5 rounded-lg py-4">
      <div class="flex flex-col">
        <Tabs
          :tabs-data="tabs"
          :tab-active="tabActive"
          @updateTabActive="changeTab"
        >
          <template v-slot:default="{ tabActive }">
            <template
              v-if="tabActive === 'Umum' && getWarehouse.data"
              class="transition duration-300 ease-in-out"
            >
              <dl class="flex w-full flex-row gap-x-4">
                <div class="w-full">
                  <dt class="text-xs text-gray-400">Kode Gudang</dt>
                  <dd class="mt-1 text-sm font-bold text-gray-800">
                    {{ getWarehouse.data.attributes.code }}
                  </dd>
                </div>
                <div class="w-full">
                  <dt class="text-xs text-gray-400">Nama Gudang</dt>
                  <dd class="mt-1 text-sm font-bold text-gray-800">
                    {{ getWarehouse.data.attributes.name }}
                  </dd>
                </div>
                <div class="w-full">
                  <dt class="text-xs text-gray-400">Kode Area</dt>
                  <dd class="mt-1 text-sm font-bold text-gray-800">
                    {{ getWarehouse.data.attributes.area_code }}
                  </dd>
                </div>
              </dl>
            </template>
            <template
              v-else-if="tabActive === 'Ekspedisi'"
              class="transition duration-300 ease-in-out"
            >
              <SetupEkspedisi />
            </template>
          </template>
        </Tabs>
      </div>
    </div>

    <success-modal v-model="success" @close="closeModal" @finish="closeModal" />
    <failed-modal v-model="failed" @close="closeModal" @previous="closeModal" />
    <template v-if="modal">
      <ExpeditionModal
        @closeModal="closeModal"
        :modal="modal"
        :formData="formData"
      />
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { StorageService } from '@/services/storage.service';
// import Modal from '@/components/Modal.vue';
import ExpeditionModal from '@/components/ExpeditionModal.vue';
import Tabs from '@/components/Tabs.vue';
import SuccessModal from '@/components/modal/success-modal.vue';
import FailedModal from '@/components/modal/failed-modal.vue';
// import Datatable from '@/components/base/Datatable';
// import { ToggleButton } from 'vue-js-toggle-button';
import SetupEkspedisi from '@/components/setup/setup-ekspedisi.vue';

export default {
  name: 'Setup',
  components: {
    // Modal,
    ExpeditionModal,
    Tabs,
    SuccessModal,
    FailedModal,
    // Datatable,
    // ToggleButton,
    SetupEkspedisi,
  },
  data() {
    return {
      tabs: ['Umum', 'Ekspedisi'],
      tabActive: 'Umum',
      expeditions: [],
      success: false,
      failed: false,
      modal: '',
      formData: null,
    };
  },
  computed: {
    ...mapGetters({
      getWarehouse: 'warehouses/getWarehouse',
      getExpeditions: 'expeditions/getExpeditions',
      getOfficeExpeditions: 'office_expeditions/getOfficeExpeditions',
    }),
  },
  methods: {
    ...mapActions({
      fetchProvinces: 'indonesia/fetchProvinces',
      fetchWarehouseById: 'warehouses/fetchWarehouseById',
      fetchOfficeExpeditions: 'office_expeditions/fetchOfficeExpeditions',
      addExpeditionToOffice: 'offices/addExpeditionToOffice',
      updateOfficeExpedition: 'office_expeditions/updateOfficeExpedition',
    }),
    changeTab(tab) {
      this.tabActive = tab;
    },
    getDataDetails(params) {
      let dataDetail = [];
      params.map((param) => {
        let data = {
          type: 'office-expeditions',
          id: param,
        };
        dataDetail.push(data);
      });
      return dataDetail;
    },
    saveExpedition() {
      const payload = {
        data: this.getDataDetails(this.expeditions),
      };
      this.addExpeditionToOffice({
        office_id: StorageService.getUser().office_id,
        payload,
      }).then((response) => {
        if (response) {
          this.success = true;
        } else {
          this.failed = false;
        }
      });
    },
    closeModal() {
      this.success = this.failed = false;
      this.modal = this.formData = null;
      this.fetchOfficeExpeditionsByOffice({
        office_id: StorageService.getUser().office_id,
      }).then((response) => {
        response.data.data.map((expedition) => {
          this.expeditions.push(expedition.id);
        });
      });
    },
  },
  created() {
    this.fetchProvinces();
    this.tabActive = this.$route.query.tab
      ? this.tabs.includes(this.$route.query.tab)
        ? this.$route.query.tab
        : 'Umum'
      : 'Umum';
    this.fetchWarehouseById({
      id: StorageService.getUser().current_warehouse,
    });
  },
};
</script>
